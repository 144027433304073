import React from "react";
import Banner from '@leafygreen-ui/banner';
import Icon from '@leafygreen-ui/icon';
import { getMostRecentPhn } from "..";

export function PhnBanner({ project }: any) {
    const phn = getMostRecentPhn(project);

  return phn ?
    <Banner className="phn-banner" variant="warning" data-test-id="phn-banner">
        <div className="phn-banner-content">
            <div className="phn-banner-text" dangerouslySetInnerHTML={{ __html: phn.Note__c }} />
            <a className="phn-banner-link" href={`https://mongodb.lightning.force.com/lightning/r/${project.caseProject._id}/related/Project_Handling_Notes__r/view`} target="_blank" rel="noopener noreferrer">
                <Icon glyph="OpenNewTab" size="xlarge" title="View all Project Handling Notes"/>
            </a>
        </div>
    </Banner>
 : null;

}