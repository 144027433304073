/**
 * Splits a first.last into a First Last
 * @param {string} s : expects format like "first.last"
 */
function getNameFromUsername(s: string) {
    let splitStr = s.split('.');
    return splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1) + ' ' + splitStr[1].charAt(0).toUpperCase() + splitStr[1].substring(1);
}
/**
 * Takes a hub data item, and adds recommended assignee from the autotc data.
 * @param {object} item 
 * @param {HubConfigDataType} autoTC 
 */
function addRecommendedAssignee(item: any, autoTC: HubConfigDataType) {
    if(item.assignee && item.assignee !== '')
    {
        //do nothing, 
        // else if item is in the autotc data and user is TC or TsTools or matches this item
    } else if((item.id in autoTC.autoTC.allAutoTCData) && 
        (autoTC.autoConfig.isTC || 
        autoTC.autoConfig.isToolsTeam || 
        autoTC.autoConfig.currentUserFI === autoTC.autoTC.allAutoTCData[item.id])) {
            return Object.assign(item, {recommendedAssignee: getNameFromUsername(autoTC.autoTC.allAutoTCData[item.id])})
    }
}
/**
 * Takes a hub data item, and adds feedback and votedDatetime from the autoTC data.
 * @param item 
 * @param autoTC 
 */
function addFeedbackData(item: any, autoTC: HubConfigDataType) {
    //if we already added a recommended assignee
    if(item.recommendedAssignee) {
        // console.log(autoTC.autoTC.allVotes,item.id);
        // debugger;
        if(autoTC.autoTC.allVotes[item.id]) {
            // we already voted
            return Object.assign(item, {feedback: autoTC.autoTC.allVotes[item.id].feedback, votedDatetime: autoTC.autoTC.allVotes[item.id].updated})
        } 
    }
}

/**
 * Takes a hub data array and extends it using the autoTCdata.
 * @param data 
 * @param autoTC 
 */
export function HubDataAddAutoTCExtension(data : any, autoTC: HubConfigDataType) : any[] {
    
    for (let prop in data) {
        if (data[prop] && data[prop].length > 0) {
            data[prop].forEach((item : any) => {
                addRecommendedAssignee(item, autoTC);
                addFeedbackData(item, autoTC);
            });
        }
    }
    return data;
}

export type HubConfigDataType = {
    autoTC : AutoTCType;
    autoConfig: AutoTCConfigType;
}

type AutoTCType = {
    allAutoTCData: {[key: string]: string},
    allAvailableTSE: {[key: string]: any},
    caseAnalysisId: string,
    allVotes: any
}
type AutoTCConfigType = {
    currentUserFI: string,
    realname: string,
    isTC: boolean,
    isToolsTeam: boolean,
    matchingTSE: boolean,
    displayRecommendation: boolean,
}

/**
 * Converts the raw autotc data into better usable object
 * TODO: Most of this could be moved into the stitch function
 * @param autoTcRaw 
 */
export function GetHubConfigData(autoTcRaw: any) : HubConfigDataType {

    let feedbacks: any = {};
    autoTcRaw.feedbacks.forEach((obj: any) => { 
        feedbacks[obj.caseNumber.toString()] = feedbacks[obj.caseNumber] ? feedbacks[obj.caseNumber] : obj;
    })

    console.log(autoTcRaw);
    let autoTC: AutoTCType = {
        allAutoTCData: autoTcRaw.hub[0].hub,
        allAvailableTSE: autoTcRaw.hub[0].available_tses,
        caseAnalysisId: autoTcRaw.hub[0]._id.toString(),
        // Hey allVotes is only used to lookup votes by casenumber
        // convert once to key: obj for easier pick up
        allVotes: feedbacks
    }
    console.log('-----allAutoTCData-----');
    console.log(autoTC);

    let autoConfig: AutoTCConfigType = {
        currentUserFI: 'angshuman.bagchi', // '' For local testing, need to match slack profile / voter profile
        realname: 'Angshuman Bagchi',
        isTC: false,
        isToolsTeam: false,
        matchingTSE: false,
        displayRecommendation: false,
    };
    
    if (autoTcRaw.currentUser && autoTcRaw.currentUser.data && autoTcRaw.currentUser.data.email) {
        autoConfig.currentUserFI = autoTcRaw.currentUser.data.email.split('@')[0];
        autoConfig.realname = autoTcRaw.currentUser.data.name;
    }
    if (autoTcRaw && autoTcRaw.slack && autoTcRaw.slack[0] && autoTcRaw.slack[0].profile)
    {
        autoConfig.isTC = (autoTcRaw.slack[0].profile.is_tc === true || autoTcRaw.users[0].ProfileName === "10gen Support Manager (INSIDER)");
    }
    const TSTOOLS_members = [
        'angshuman.bagchi',
        'ben.longberg',
        'benjamin.zimmerman',
        'davide.deangelis',
        'nicholas.tang',
        'paul.rooney',
        'ramon.fernandez',
        'samuel.barone',
        'thiago.galesi',
        'mitesh.gosavi',
        'jonathan.delcarmen',
        'mitch.marcus',
        'ester.marti-vilaseca'
    ];
    autoConfig.isToolsTeam = TSTOOLS_members.includes(autoConfig.currentUserFI);
    if (Object.values(autoTC.allAutoTCData).indexOf(autoConfig.currentUserFI) > -1) {
        autoConfig.matchingTSE = true;
    }
    console.log('CHECKING displayRecommendation');
    console.log(autoConfig.currentUserFI);
    console.log(Object.keys(autoTC.allAutoTCData).length);
    console.log(autoTC.allAutoTCData);
    console.log(autoConfig.isTC);
    console.log(autoConfig.matchingTSE);
    console.log(autoConfig.isToolsTeam);

    if (Object.keys(autoTC.allAutoTCData).length !== 0 && 
        (autoConfig.isTC || autoConfig.matchingTSE || autoConfig.isToolsTeam)) {
        autoConfig.displayRecommendation = true;
    }


    return {
        autoTC,
        autoConfig
    };
}