import React from 'react';
import "./Loading.css";

type LoadingProps = {
    text: string,
    speed: number
}

type LoadingState = {
    content: string
}

export default class Loading extends React.Component<LoadingProps, LoadingState> {
    constructor(props: LoadingProps) {
        super(props);
        this.state = {
            content: this.props.text
        }
    }
    
    interval: any = {};

    static defaultProps = {
        text: 'Loading',
        speed: 300
    }

    componentDidMount() {
        const { text, speed } = this.props;

        this.interval = window.setInterval(() => {
            this.state.content === text + '...'
                ? this.setState({ content: text })
                : this.setState(({ content }) => ({ content: content + '.' }));
        }, speed);
    }

    componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    render() {
        return (
            <p className="Loading">
                {this.state.content}
            </p>
        )
    }
}