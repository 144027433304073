export function getMostRecentPhn(project: any) {
  let phn;
  const currentDate : Date = new Date();

  const checkApproved = (note : any) => {
    const status = (note.Approval_Status__c === 'Approved') ? true : false;
    return status;
  }

  const checkActive = (note : any) => {
    const active = (currentDate <= note.End_Date__c && !note.isDeleted) ? true : false;
    return active;
  }

  const getRecentNote = (project : any) => {
    let note = null;
    if (project && project.caseProject && Array.isArray(project.caseProject.project_handling_notes) && 
          project.caseProject.project_handling_notes.length > 0) {
      const filteredProjectsNotes = project.caseProject.project_handling_notes.filter((note:any) => (checkActive(note) && checkApproved(note)));
      if(filteredProjectsNotes && filteredProjectsNotes.length > 0) {
        note = filteredProjectsNotes.reduce((max: any, phn: any) => max.CreatedDate > phn.CreatedDate ? max : phn);
      }
    }
      return note;
    };

    phn = getRecentNote(project);

  return phn;
}

export { PhnIcon } from './PhnIcon/PhnIcon';
export { PhnBanner } from './PhnBanner/PhnBanner';
