export type HubDataType = {
    title: string,
    hubAbbr: string,
    hubCountProp: Function,
    views: string[],
}

/**
 * This is the basic translation table for hub views.
 * A hub view, has a stitch abbreviation, a number of views, a title, 
 * and a function to sum the table counts (used in the nav).
 */
export default function InitialTableConfig() : HubDataType[] {
    return [
        {
            title: 'Traffic Cop',
            hubAbbr: 'TC',
            hubCountProp: (hubItem: any) => hubItem.sla_count,
            views: ['SLA', 'FSA', 'UNA', 'REVACT']
        }, {
            title: 'FTS',
            hubAbbr: 'FTS',
            hubCountProp: (hubItem: any) => hubItem.fsa_count + "/" + (hubItem.fsa_count + hubItem.fsw_count) ,
            views: ['FSA', 'FSW', 'FTSA']
        }, {
            title: 'My Cases',
            hubAbbr: 'USER',
            hubCountProp: (hubItem: any) => hubItem.user_count,
            views: ['USERASSIGNED', 'USERASSIGNEDOTHER', 'USERWAITING', 'USERONHOLD', 'USERRESOLVED']
        }, 
        {
            title: 'Reviews',
            hubAbbr: 'REVS',
            hubCountProp: (hubItem: any) => hubItem.reviews_count,
            views: ['REVACT', 'REVDONE']
        },
        {
            title: 'Unassigned',
            hubAbbr: 'UNAS',
            hubCountProp: (hubItem: any) => hubItem.unas_count,
            views: ['UNAS', 'UNWAITING']
        }, {
            title: 'Active',
            hubAbbr: 'ACTIVE',
            hubCountProp: (hubItem: any) => hubItem.active_count,
            views: ['ACTS']
        }, {
            title: 'Waiting',
            hubAbbr: 'WAITS',
            hubCountProp: (hubItem: any) => hubItem.waiting_count,
            views: ['WAIT', 'AUTOCLOSE']
        }, {
            title: 'Next Up',
            hubAbbr: 'TCW',
            hubCountProp: (hubItem: any) => hubItem.tcw_count ? hubItem.tcw_count : "0",
            views: ['TCW', 'REVACT']
        }
    ]

}