import React from "react";
import "./LoadScore.css";

type Props = {
    score: number;
    _id: string;
    isCarousell: boolean;
    eztc_inspector_url: string
}

const scoreMap: any = {
    "0": {color: "#FFFFFF", short: "None"},
    "10": {color: "#FCE6e5", short: "Low"},
    "20": {color: "#facdcc", short: "Low"},
    "30": {color: "#f7b4b2", short: "Low"},
    "40": {color: "#f59b99", short: "Low"},
    "50": {color: "#f2827f", short: "Medium"},
    "60": {color: "#f06966", short: "Medium"},
    "70": {color: "#ed504c", short: "Medium"},
    "80": {color: "#eb3733", short: "Moderate"},
    "90": {color: "#e81e19", short: "Moderate"},
    "100": {color: "#e60600", short: "High"},
    "120": {color: "#e60600", short: "High"},
    "140": {color: "#e60600", short: "High"},
};

/**
 * Constructs a nav bar given an array of NavItems and a current selected Item.
 */
class LoadScore extends React.Component<Props> {
    /**
     * This particular function should only allow rendering when:
     * 1) the currrentItem has changed
     * 2) the data inside the array has changed
     *    ie.  item.title === olditem.title && item.count === olditem.count
     * @param newProps
     */
    shouldComponentUpdate(newProps: Props) {
        return (this.props.score !== newProps.score);
    }

    getStyle(score: Number) {
        let color: string = "#8e898c";
        for(let prop in scoreMap) {
            if(score >= Number.parseInt(prop)) {
                color = scoreMap[prop].color;
            }
        }
        if(score > 60) {
            return {background: color, color: "#FCEBE2"}
        } else {
            return {background: color};
        }
    }
    

    getScoreRounded(score: number) {
        return <div className="sla-badge load-score" style={this.getStyle(score)}>{Math.round(score)}</div>
    }

    getLoadscoreLink(_id: string) {
        const score = this.props.score;
        if (this.props.isCarousell) {
            return <a className="load-score-carousel" href={this.props.eztc_inspector_url + '/loadscore/' + _id} target="_blank" rel="noopener noreferrer">
                    Load Score: <b>{score && Math.round(score)}</b></a>
        } else {
            return <a href={this.props.eztc_inspector_url + '/loadscore/' + _id} target="_blank" rel="noopener noreferrer">{this.getScoreRounded(score)}</a>
        }
    }

    render() {
        const _id = this.props._id;
        return (
            this.getLoadscoreLink(_id)
        );
    }
}

export default LoadScore;