import React from "react";
import "./TCHelperCard.css";
import Card from '@leafygreen-ui/card';
import Badge from '@leafygreen-ui/badge';
import ToolTip from '@leafygreen-ui/tooltip';
import {FaInfoCircle} from 'react-icons/fa';
import LoadScore from "../TCHelperFull/LoadScore";
import "../TCHelperFull/LoadScore.css";

type TCHelperCardProps = {
    item: any,
    eztc_inspector_url: string
}

type TCHelperCardState = {

}

const skillsMap: any = {
    "Atlas": {
        color: "#72cc35",
        short: "At"
    },
    "Atlas - Performance": {
        color: "#72cc35",
        short: "Perf"
    },
    "Atlas - Billing & Account": {
        color: "#72cc35",
        short: "B&A"
    },
    "Atlas - Migrations": { 
        color: "#72cc35",
        short: "Migr"
    },
    "Atlas - Onboarding": {
        color: "#72cc35",
        short: "Onb"
    },
    "Atlas - Other": {  //Added this after pull from hub.js
        color: "#72cc35",
        short: "Atlas"
    },
    "Atlas - Security": {
        color: "#72cc35",
        short: "Sec"
    },
    "CM - Automation": {
        color: "#11ccb8",
        short: "Atm"
    },
    "CM - Monitoring": {
        color: "#11ccb8",
        short: "Mon"
    },
    "CM - Backup": {
        color: "#11ccb8",
        short: "Bckp"
    },
    "CM - Other": {
        color: "#11ccb8",
        short: "Other"
    },
    "Install/Upgrade": {
        color: "#b40e01",
        short: "I/U"
    },
    "Core": {
        color: "#b40e01",
        short: "Core"
    },
    "Security - SSL": {
        color: "#b40e01",
        short: "SSL"
    },
    "Security - Authentication": {
        color: "#b40e01",
        short: "Auth"
    },
    "Sharding": {
        color: "#b40e01",
        short: "Shr"
    },
    "Replication": {
        color: "#b40e01",
        short: "Rpl"
    },
    "Performance": {
        color: "#b40e01",
        short: "Perf"
    },
    "Other": {
        color: "#b40e01",
        short: "Other"
    },
    "Administrative": {
        color: "#b40e01",
        short: "Adm"
    },
    "Queries": {
        color: "#b40e01",
        short: "Qrs"
    },
    "Indexing": {
        color: "#b40e01",
        short: "Idx"
    },
    "Drivers": {
        color: "#b40e01",
        short: "Drv"
    },
    "Driver-C#": {
        color: "#b40e01",
        short: "C#"
    },
    "Driver-Node": {
        color: "#b40e01",
        short: "Node"
    },
    "Driver-Java": {
        color: "#b40e01",
        short: "Java"
    },
    "Enterprise Build - LDAP": {
        color: "#b40e01",
        short: "LDAP"
    },
    "Enterprise Build - General": {
        color: "#b40e01",
        short: "Gen"
    },
    "A&I": {
        color: "#5394fe",
        short: "A&I"
    },
    "BI Connector": {
        color: "#5394fe",
        short: "Bic"
    },
    "OM - Install or Upgrade": {
        color: "#5394fe",
        short: "I/U"
    },
    "OM - Backup": {
        color: "#5394fe",
        short: "Bckp"
    },
    "OM - Security": {
        color: "#5394fe",
        short: "Sec"
    },
    "OM - Monitoring": {
        color: "#5394fe",
        short: "Mon"
    },
    "OM - Sizing": {
        color: "#5394fe",
        short: "Szng"
    },
    "OM - Automation": {
        color: "#5394fe",
        short: "Atm"
    },
    "OM - Other": {
        color: "#5394fe",
        short: "Other"
    },
    "A&I/Kerberos": {
        color: "#5394fe",
        short: "Krb"
    },
    "Language": {
        color: "#feacb8",
        short: "Fr"
    },
    "Language/French": {
        color: "#feacb8",
        short: "Fr"
    },
    "Language/Italian": {
        color: "#feacb8",
        short: "It"
    },
    "Language/Spanish": {
        color: "#feacb8",
        short: "Es"
    }
};

class TCHelperCard extends React.Component<TCHelperCardProps, TCHelperCardState> { 
    state = {
    }

    convertSkill = (skill: string) => {
        let conv: any = skillsMap[skill];
        return conv != null ? conv.short : skill.slice(0, 5);
    }
    getNameLink = (item: any) => {
        return (item && item.slack_profile && item.slack_profile.team_id) ? <a href={"slack://user?team=" + item.slack_profile.team_id + "&id=" + item.slack_profile.id }>{this.getName(item)}</a> : <span>{this.getName(item)}</span>
    }
    getName = (item: any) => {
        return (item && item.FirstName && item.LastName) ? 
            item.FirstName + ' ' + item.LastName :
            (item && item.FederationIdentifier) ? item.FederationIdentifier.split('@')[0] : 'No-Data';
    }
    getRegion = (item: any) => {
        return (item && item.TS_Region__c) ? item.TS_Region__c : 'N/A';
    }
    getTeam = (item: any) => {
       return (item && item.TS_Team__c) ? item.TS_Team__c : 'N/A';
    }

    getDotStatusClass = (item: any) => {
        // Determine availability green/grey dot by checking slack_profile.presence has keyword 'active'
        let avail = (item && item.slack_profile && item.slack_profile.presence && item.slack_profile.presence === 'active');
        return 'dot ' + (avail ? 'available' : 'notavailable');
    }

    summarizeTickets = (item: any) => {
        let tickets = [];
        if(item && item.active) {
            for(let prop in item.active) {
                if(item.active[prop].length && item.active[prop].length > 0 ) {
                    tickets.push(prop.toUpperCase() + ': ' + item.active[prop].length);
                }
            }
        }
        
        if(item && item.waiting) {
            let count = 0;
            for(let prop in item.waiting) {
                if(item.waiting[prop].length) {
                    count += item.waiting[prop].length;
                }
            }
            if( count > 0) {
                tickets.push('W: ' + count);
            }
        }
        return tickets.length > 0 ? tickets.join(', ') : '0';
    }

    summarizeSkillLevels = (skills: any, level: string) => {
        if(skills && skills.length) {
            let set: any[] = skills.filter((x: any) => x.Skill_Level__c === level);
            return set.length > 0 ? set.map(x => x.Skill__c).join(' | ') : 'None';
        } else {
            return 'None';
        }
    }

    roundScore = (item: any) => {
        if (item && item.score) {
            return Math.round(item.score);
        }
    }

    render() { 
        // eslint-disable-next-line
        const { item } = this.props;
        return (
            <Card className="tc-card">
                <ToolTip align="bottom" justify="end" trigger={<span className="tc-tooltip"><FaInfoCircle /></span>} triggerEvent="hover">
                    <div className="tc-tooltip-popover"> 
                        <b>Tickets</b><br/>
                        {this.summarizeTickets(item)}<br/>
                        <b>Level 3</b><br/>
                        {item && this.summarizeSkillLevels(item.Skills, '3')}<br/>
                        <b>Level 2</b><br/>
                        {item && this.summarizeSkillLevels(item.Skills, '2')}<br/>
                        <b>Level 1</b><br/>
                        {item && this.summarizeSkillLevels(item.Skills, '1')}<br/>
                    </div>
                </ToolTip>
                <LoadScore score={item && item.score} _id={item &&  item._id} isCarousell={true} eztc_inspector_url={this.props.eztc_inspector_url}/>
                <div className="tc-card-user"><span className={this.getDotStatusClass(item)}></span>{this.getNameLink(item)}</div>
                <div className="tc-card-badges row flex-center">{item && item.Skills && item.Skills.length > 0 && item.Skills.slice(0,6).map((skill: any, index: Number) => (
                    <div key={item._id + '-' + index} className="badge">
                        <Badge autoCapitalize="true" title={skill.Skill__c} className="tc-badge badge-lightgray">
                            {this.convertSkill(skill.Skill__c)}
                        </Badge>
                    </div>
                ))}</div>
                <div className="tc-card-footer row">
                    <div className="column left">{this.getRegion(item)}</div>
                    <div className="column right">{this.getTeam(item)}</div>
                </div>
            </Card>
        )
    }

}

export default TCHelperCard;