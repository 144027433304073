/* eslint-disable react-hooks/exhaustive-deps */ 
import React, { FunctionComponent, useEffect, useState } from "react"; 
import Modal from '@leafygreen-ui/modal';
import ReactMarkdown from 'react-markdown';
import Loading from "../Loading/Loading";
import "./DraftModal.css";
import Button from "@leafygreen-ui/button"; 
import { useWidth } from "../_hooks/useWidth";

const escalationBoxStyle = {
    display: 'inline-block',
    border: '1px solid black',
    padding: '1rem',
};

type Props = {
    callback: any,
    stitchClient: any
}

type ftsObj = {
    render: string,
    count: number,
}

const DraftModal: FunctionComponent<Props> = (props: Props) => {
    const [isOpen, setIsOpen] = useState(true); 
    const [ftsDraftDataLoaded, setFTSDraftDataLoaded] = useState(false); 
    const [activeFTS, setActiveFTS] = useState({ render: '(none)', count: 0 } as ftsObj);
    const [waitingFTS, setWaitingFTS] = useState({ render: '(none)', count: 0 } as ftsObj);
    const [removedFTS, setRemovedFTS] = useState({ render: '(none)', count: 0 } as ftsObj);

    const width = useWidth();

    useEffect(() => {
        getFTSDraftData(props.stitchClient);
    }, []);

    const getFTSDraftData = (client: any) => {
        client && client.callFunction("getFTSDraftData").then((response: any) => {
            console.log(`-----Get getFTSDraftData view Data: `, response);
            if (response.status === 'success') {
                console.log('Getting FTS Draft Data -> ' + response.status);

                let ftsDraftData = response.data;
                let activeFTS = ftsDraftData.activeFTS;
                let waitingFTS = ftsDraftData.waitingFTS;
                let removedFTS = ftsDraftData.removedFTS;
 
                setFTSDraftDataLoaded(true); 
                renderActiveFTSDraft(activeFTS);
                renderWaitingFTSDraft(waitingFTS);
                renderRemovedFTSDraft(removedFTS);

            } else {
                console.log('Error: No Data for DraftModal');
            }
        }).catch((err: any) => {
            console.error(err);
        })
    }

    const isLoading = () => !ftsDraftDataLoaded;

    const renderActiveFTSDraft = (activeFTS: any) => {
        console.log(`Getting ${activeFTS.length} active FTS`); 

        let ftsWrapperList = [];

        if (activeFTS.length > 0) {
            for (let doc of activeFTS) {
                let element = document.createElement("div");
                let anchorURL = document.createElement('a');
                anchorURL.href = doc.issue_url;
                anchorURL.target = "_blank";
                anchorURL.innerHTML = doc.issue_key;
                let boldContent = document.createElement('b');
                boldContent.appendChild(anchorURL);
                let hdrText = '\xa0' + doc.company_and_proj_hdr_txt;
                let text = document.createTextNode(hdrText);
                boldContent.appendChild(text);
                element.appendChild(boldContent);
                // console.log(element); 
                let commentBody = document.createElement('div');
                //let md = doc.fts_comment_markdown;
                // console.log(`md of ${doc.issue_key}: `);
                // console.log(md); 
                commentBody.className = 'fts_comment_body'; 
                let activeFTSWrapper = document.createElement('div');
                activeFTSWrapper.appendChild(element);
                activeFTSWrapper.appendChild(commentBody); 
                ftsWrapperList.push(activeFTSWrapper);
            }

            let parent = document.createElement('div');
            for (let item of ftsWrapperList) {
                parent.appendChild(item);
            }
 
            setActiveFTS({
                render: parent.outerHTML,
                count: activeFTS.length
            }) 
        }
    };

    const renderWaitingFTSDraft = (waitingFTS: any) => {
        console.log(`Getting ${waitingFTS.length} waiting FTS`); 

        let waitingWrapperList = [];

        if (waitingFTS.length > 0) {
            for (let doc of waitingFTS) {
                let element = document.createElement("div");
                let anchorURL = document.createElement('a');
                anchorURL.href = doc.issue_url;
                anchorURL.target = "_blank";
                anchorURL.innerHTML = doc.issue_key;
                let boldContent = document.createElement('b');
                boldContent.appendChild(anchorURL);
                let hdrText = '\xa0' + doc.company_and_proj_hdr_txt;
                let text = document.createTextNode(hdrText);
                boldContent.appendChild(text);
                element.appendChild(boldContent);

                let commentBody = document.createElement('div');
                //let md = doc.fts_comment_markdown;
                commentBody.className = 'fts_comment_body'; 

                let waitingFTSWrapper = document.createElement('div');
                waitingFTSWrapper.appendChild(element);
                waitingFTSWrapper.appendChild(commentBody); 
                waitingWrapperList.push(waitingFTSWrapper);
            }

            let parent = document.createElement('div');
            for (let item of waitingWrapperList) {
                parent.appendChild(item);
            } 
       
            setWaitingFTS({ 
                render: parent.outerHTML, 
                count: waitingFTS.length 
            }); 
        }
    };

    const renderRemovedFTSDraft = (removedFTS: any) => {
        console.log(`Getting ${removedFTS.length} removed FTS`); 

        let removedFTSWrapperLst = [];

        if (removedFTS.length > 0) {
            for (let doc of removedFTS) {
                let element = document.createElement("p");
                let elementText = doc.meta.casenumber + '\xa0' + doc.meta.company;
                console.log(elementText);
                element.innerText = elementText;

                let removedFTSWrapper = document.createElement('div');
                removedFTSWrapper.appendChild(element);

                removedFTSWrapperLst.push(removedFTSWrapper);
            }

            let parent = document.createElement('div');
            for (let item of removedFTSWrapperLst) {
                parent.appendChild(item);
            }
 
            setRemovedFTS({ 
                render: parent.innerHTML, 
                count: removedFTS.length 
            }); 
        }
    }

    const onClose = () => {
        props.callback(); 
        setIsOpen(!isOpen);
    }

    const onSize = () => {
        let size: any = 'default';

        switch (width) {
            case 'xs':
            case 'sm':
                size = 'small';
                break;
            case 'md':
                size = 'default';
                break;
            case 'lg':
            case 'xl':
                size = 'large';
                break;
        }

        return size;
    }

    const selectAndCopyRange = () => {
        copyToClip(document.getElementById('fts_email_draft')?.innerHTML);
    }

    const copyToClip = (str: any) => {
        function listener(e: any) {
            e.clipboardData.setData("text/html", str);
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        }

        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
    };

    return (
        <Modal
            size={onSize()}
            contentClassName="modalBox"
            open={isOpen}
            setOpen={onClose}>
            {isLoading() && <Loading text={`Fetching FTS Draft Data`} />}
            <Button
                variant='primary'
                title={'Copy'}
                onClick={(ev: any) => selectAndCopyRange()}
            >
                Copy
            </Button>
            {!isLoading() &&

                <div id="fts_email_draft">
                    <div>
                        <p className="section_hdr">Active (<span>{activeFTS.count}</span>)</p>
                        <ReactMarkdown children={activeFTS.render} escapeHtml={false} />
                    </div>
                    <div>
                        <p className="section_hdr">Watch (<span>{waitingFTS.count}</span>)</p>
                        <ReactMarkdown children={waitingFTS.render} escapeHtml={false} />
                    </div>
                    <div>
                        <p className="section_hdr">Removed (<span>{removedFTS.count}</span>)</p>
                        <ReactMarkdown children={removedFTS.render} escapeHtml={false} />
                    </div>
                    <div id="escalationBox">
                        <p className="section_hdr" style={escalationBoxStyle}>
                            <a href={"https://hub.corp.mongodb.com/00OA0000006cSlV"} target={"_blank"} rel="noopener noreferrer">
                                Open Case Escalations
                            </a>
                        </p>
                    </div>
                </div> 
            }
        </Modal>
    );
}

export default DraftModal; 