type GlobalStorageType = {
    wasLastKeyUpCtrl: boolean | null;
    wasLastKeyDownCtrl: boolean | null;
    didGetClick: boolean;
    autoTCData:any;
    stitchClient: any;
    openDetailsCallback: Function;
}

const GlobalStorage: GlobalStorageType = {
    wasLastKeyUpCtrl: null, 
    wasLastKeyDownCtrl: null,
    didGetClick: false,
    autoTCData: null,
    stitchClient: null,
    openDetailsCallback: (item: any) => {}
}
export default GlobalStorage