import * as React from "react";
import BaseTable, { Column, SortOrder } from "react-base-table";
import "react-base-table/styles.css";
import "./AutodeskTableWrapper.css";

import * as TableConfig from "../_Configs/AutodeskHubColumns";
import { alphanumericCollator } from "../_Generics/alphanumericCollator";
import Badge from "@leafygreen-ui/badge";
import Button from "@leafygreen-ui/button";
import DraftModal from "../DraftModal/DraftModal";
import ActionModal from "../ActionModal/ActionModal";
import { StitchAppClient } from "mongodb-stitch-browser-sdk";
import { debounce } from "throttle-debounce";
import GlobalStorage from "../_Generics/GlobalStorage";
import BaseTableResizer from "./AutodeskResizingTableWrapper";
import { FaBicycle } from "react-icons/fa";
import Checkbox from "@leafygreen-ui/checkbox";
import CheckboxFilter from "../CheckboxFilter/CheckboxFilter";
import { css } from "@ts-tools/emotion";
import moment from "moment";

type Props = {
  hub: string;
  views: string[];
  data: any;
  client: StitchAppClient | undefined;
  TC: string;
  currentUser: any;
  displayRecommendation: boolean;
};
type State = {
  tableConfig: any;
  components: any;
 
  isDetailsOpen: boolean;
  isEmailDraftOpen: boolean;
  selectedItem: any;
   
  // preferences
  sortState: any;
  orderOfSorts: any; 
  showEmptyUserTables: boolean;
  selectedTicketTypeFilter: Array<string>;
  searchString: string;
  jiraChecked: boolean;
  showAllSLA: boolean;
  showAllPending: boolean; 
  
};

const recommendationBadgeStyle = css`
  flex-grow: 1;
  align-self: center;
  display: flex;
  justify-content: flex-end;
`;

export class AutodeskTableWrapper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const renderers: { [key: string]: any } = TableConfig.Renderers;
    const tableConfig: any = TableConfig.ColumnConfig();

    let orderOfSorts: any = {};
    let sortState: any = {};
    for (let prop in tableConfig) {
      orderOfSorts[prop] = tableConfig[prop].defaultSort.orderOfSorts;
      sortState[prop] = tableConfig[prop].defaultSort.sortState;
    }

    const Cell = (cellProps: any) => {
      //const format = cellProps.column.format || 'string'
      let format = cellProps.column.format || "string";
      if (this.props.hub === "TC") format = cellProps.column.format_tc || "string"
      else if (this.props.hub === "USER")
        format = cellProps.column.format_user || "string";

      const renderer = renderers[format] || renderers.string;

      return renderer(cellProps);
    }; 
 
    let showEmptyUserTables = true;
    let selectedTicketTypeFilter = ["Core", "A&I", "Atlas", "Realm"];
    let searchString = "";
    let jiraChecked =  false;
    let showAllSLA = false;
    let showAllPending = false;

    let storage;
    if (localStorage) {
      const item = localStorage.getItem('preferences');
      if (item) {
        storage = JSON.parse(item);
        // NOTE: currently just saving the jiraChecked setting, if you want to add more, just uncomment/add, and 
        //       make sure you call: savePreferences method and uncomment/add in the method
        // sortState = storage.sortState;
        // orderOfSorts = storage.orderOfSorts;
        // showEmptyUserTables = storage.showEmptyUserTables;
        // selectedTicketTypeFilter = storage.selectedTicketTypeFilter;
        // searchString = storage.searchString;
        jiraChecked = storage.jiraChecked;
        // showAllSLA = storage.showAllSLA;
        // showAllPending = storage.showAllPending;
      }
    } 

    this.state = {
      tableConfig: tableConfig,
      components: {
        TableCell: Cell,
      },

      isDetailsOpen: false,
      selectedItem: null,
      isEmailDraftOpen: false,

      // preferences
      sortState,
      orderOfSorts,
      showEmptyUserTables,
      selectedTicketTypeFilter,
      searchString,
      jiraChecked,
      showAllSLA,
      showAllPending

      // showEmptyUserTables: true,
      // selectedTicketTypeFilter: ["Core", "A&I", "Atlas", "Realm"],
      // searchString: "",
      // jiraChecked:  false,
      // showAllSLA: false,
      // showAllPending: false 
    }; 

    // What are we doing here?
    // We are storing the keyup key down to check if ctrl has been pressed while doing sorting
    // This is slightly buggy (as the last time you are doing a multi search, will bleed into doing a single click)
    GlobalStorage.wasLastKeyUpCtrl = null;
    GlobalStorage.wasLastKeyDownCtrl = null;
    window.onkeyup = function (e: any) {
      GlobalStorage.wasLastKeyUpCtrl = e.key === "Control";
    };
    window.onkeydown = function (e: any) {
      GlobalStorage.wasLastKeyDownCtrl = e.ctrlKey;
    };

    GlobalStorage.stitchClient = props.client;

    GlobalStorage.openDetailsCallback = (itemId: any) =>
      this.showDetailsModal(itemId);
  }
  
  onColumnSort = (vars: any, view: string) => {
    //console.log(GlobalStorage.wasLastKeyDownCtrl, GlobalStorage.wasLastKeyUpCtrl);
    const isMultiSort =
      GlobalStorage.wasLastKeyDownCtrl && !GlobalStorage.wasLastKeyUpCtrl;
    GlobalStorage.wasLastKeyUpCtrl = null;
    GlobalStorage.wasLastKeyDownCtrl = null;
    GlobalStorage.didGetClick = false;

    const { sortState, orderOfSorts } = this.state;
    const { key, order } = vars;

    //TODO EXPAND THIS TO BE ABLE TO SORT ORDER BY MULTIPLE
    let viewSortState = sortState[view] ? sortState[view] : {};
    let newViewSort: any;
    let newViewOrderOfSorts = orderOfSorts[view] ? orderOfSorts[view] : [];
    if (isMultiSort) {
      let newViewState = viewSortState[key] === SortOrder.DESC ? null : order;
      // clear the sort state if the previous order is desc
      newViewSort = {
        ...viewSortState,
        [key]: newViewState,
      };
      if (orderOfSorts[view] && orderOfSorts[view].includes(key)) {
        //do nothing
      } else {
        newViewOrderOfSorts.push(key + "");
      }
    } else {
      newViewSort = {
        [key]: viewSortState[key] === SortOrder.DESC ? null : order,
      };
      newViewOrderOfSorts = [key + ""];
    }
    //console.log(newViewSort);
    this.setState({
      orderOfSorts: {
        ...orderOfSorts,
        [view]: newViewOrderOfSorts,
      },
      sortState: {
        ...sortState,
        [view]: newViewSort,
      },
    });

    // this.savePreferences('orderOfSorts',{
    //   ...orderOfSorts,
    //   [view]: newViewOrderOfSorts,
    // } ); 

    // this.savePreferences('sortState',{
    //   ...sortState,
    //   [view]: newViewSort,
    // } ); 
 
  };

  doSort(data: any) {
    const { orderOfSorts, sortState, tableConfig } = this.state;
    //console.log(sortState);
    //console.log(orderOfSorts);
    const sortData = data;

    // for each view sort
    for (let prop in sortState) {
      // if view sort applies to a view in the data and it has data in it
      if (sortData && sortData[prop] && sortData[prop].length > 1) {
        const viewConfig = tableConfig[prop];
        // for each column that is being sorted

        sortData[prop] = sortData[prop].sort((a: any, b: any) => {
          let result = 0;
          if (orderOfSorts[prop] && orderOfSorts[prop].length) {
            for (let i = 0; i < orderOfSorts[prop].length; i++) {
              const sortKey = orderOfSorts[prop][i];
              //let sortProp = sortState[prop][sortKey];

              const column = viewConfig.columns.find(
                (v: any) => v.key === sortKey
              );
              const aVal =
                column && column.sortAccessor
                  ? column.sortAccessor(a)
                  : a[sortKey];
              const bVal =
                column && column.sortAccessor
                  ? column.sortAccessor(b)
                  : b[sortKey];
              result = alphanumericCollator(
                sortState[prop][sortKey] !== SortOrder.ASC
              )(aVal, bVal);
              if (result !== 0) {
                return result;
              }
            }
          }
          // for(let sortProp in sortState[prop]) {
          //     const column = viewConfig.columns.filter((v: any) => v.key === sortProp);
          //     const aVal = column.sortAccessor ? column.sortAccessor(a) : a[sortProp];
          //     const bVal = column.sortAccessor ? column.sortAccessor(b) : b[sortProp];
          //     result = alphanumericCollator(sortState[prop][sortProp] !== SortOrder.ASC)(aVal, bVal);
          //     if(result !== 0) {
          //         return result;
          //     }
          // }
          return result;
        });
      }
    }
    return sortData;
  }

  swapShowEmptyUserTables = (ev: any) => {
    this.setState({
      showEmptyUserTables: !this.state.showEmptyUserTables,
    });
 
   //this.savePreferences('showEmptyUserTables', !this.state.showEmptyUserTables)

  };
  onGenerateFTSEmailDraftOpen = () => {
    this.setState({ isEmailDraftOpen: true });
  };

  onGenerateFTSEmailDraftClose = () => {
    this.setState({ isEmailDraftOpen: false });
  };
  onDetailsModalClose = () => {
    this.setState({
      selectedItem: null,
      isDetailsOpen: false,
    });
  };

  rowClassName(data: any) {
    const { rowData } = data;
    return rowData.recommendedAssignee != null ? "has-recommend" : "";
  }
  updateFilterSearchAll = () => {
    let allFilters = ["Core", "A&I", "Atlas", "Realm"];

    this.state.selectedTicketTypeFilter.length > 3 ?
      this.setState({ selectedTicketTypeFilter: [] }) : this.setState({ selectedTicketTypeFilter: allFilters });

      //this.savePreferences('selectedTicketTypeFilter', this.state.selectedTicketTypeFilter.length > 3 ? [] : allFilters);
  }

  updateFilterSearch = (ev: any, data: any) => {
    if (data.length > 0) {
      let newData = data.filter((value: any, index: any, arr: any) => {
        return value;
      });
      this.setState({ selectedTicketTypeFilter: newData });
      //this.savePreferences('selectedTicketTypeFilter', newData)
    } else {
      this.setState({ selectedTicketTypeFilter: data });
      //this.savePreferences('selectedTicketTypeFilter', data)
    }
  };

  updateTextSearchDebounced = debounce(250, (val: string) => {
    if (this.state.searchString !== val) {
      this.setState({
        searchString: val,
      });  
      //this.savePreferences('searchString', val)
     }
  });

  searchFilter(data: any, view: any): any {

    // Possible Product Values 

    // [
    //   'Atlas',
    //   'Atlas Enterprise',
    //   'Atlas Platinum',
    //   'BI Connector',
    //   'Cloud Manager',
    //   'Compass',
    //   'Data Lake',
    //   'MongoDB',
    //   'MongoDB Enterprise Advanced',
    //   'Ops Manager',
    //   'Realm',
    //   'Serverless (Beta)',
    //   'Stitch',
    //   'Support Site'
    // ]

    const { selectedTicketTypeFilter, searchString, jiraChecked } = this.state;
    let newArrays: { [key: string]: any } | null = null;
    let newUSERASSIGNEDOTHER: any;

    let selectedAllFilters = (selectedTicketTypeFilter.includes('Atlas')) &&
      (selectedTicketTypeFilter.includes('Realm')) &&
      (selectedTicketTypeFilter.includes('Core')) &&
      (selectedTicketTypeFilter.includes('A&I'));

    if (!selectedAllFilters) {
      // FILTER TYPES
      if (data) {
        for (let prop in data) {
          if (newArrays == null) {
            newArrays = {};
          }
          newArrays[prop] = data[prop].filter((item: any) => {
            let cloudProject = (item.cloudProject && item.cloudProject.length > 0);
            // if Realm products exists
            if (selectedTicketTypeFilter.includes("Realm") &&
              item.product === "Realm") {
              return true;
            }
            // if sfsc and cloud project doesnt exist but including Cloud Manager and Ops Manager products
            else if (
              selectedTicketTypeFilter.includes("A&I") &&
              (!cloudProject &&
                (item.product === "Cloud Manager" ||
                  item.product === "Ops Manager"))
            ) {
              return true;
            }
            // if sfsc and cloud project doesnt exist and it's not a Cloud Manager, Ops Manager, Atlas or Realm product
            else if (selectedTicketTypeFilter.includes("Core") &&
              (!cloudProject &&
                item.product !== "Cloud Manager" &&
                item.product !== "Ops Manager" &&
                item.product !== "Atlas" &&
                item.product !== "Realm")
            ) {
              return true;
            }
            // if cloud and cloud project exists and the product is Atlas
            else if (
              selectedTicketTypeFilter.includes("Atlas") &&
              (item.product === "Atlas" ||
                (cloudProject && item.product !== "Realm"))
            ) {
              return true;
            }
            return false;
          });
        }
      }
    }

    // if no text search, exit.
    if (searchString && searchString.length === 0) {
      return newArrays ? newArrays : data;
    } else {

      // if newArrays is still empty (then there was no filter)
      if (newArrays == null && data) {
        newArrays = {};
        Object.assign(newArrays, data);
      } //TODO: performance hit ?
      const case_type = ["sla", "fts", "una"];
      for (let prop in newArrays) {
        newArrays[prop] = newArrays[prop].filter((item: any) => {
          if (case_type.includes(searchString)) {
            if (item.tcwtypology === searchString.toUpperCase()) {
              return item;
            }
          } else {
            return item.quickSearch.indexOf(searchString) >= 0;
          }
          return 0;
        });
      }
      if (!jiraChecked) {
        if (!newArrays) {
          newArrays = {};
          Object.assign(newArrays, data);
        }
        newUSERASSIGNEDOTHER = newArrays['USERASSIGNEDOTHER'];

        if (data && data['USERASSIGNEDOTHER'] && newUSERASSIGNEDOTHER) {
          newUSERASSIGNEDOTHER = data['USERASSIGNEDOTHER'].filter((item: any) => {
            if (item.jiraCaseId == null) return item;
            else return null;
          })
        }
        if (newArrays) newArrays['USERASSIGNEDOTHER'] = newUSERASSIGNEDOTHER;

      }

      return newArrays;
    }
  }

  showDetailsModal = (itemId: any) => {
    // const { rowData } = data;
    const { data, views } = this.props;
    let item: any;
    views.forEach((v) => {
      let found = data[v].find((i: any) => i.id === itemId);
      if (found) {
        item = found;
      }
    });
    this.setState({
      isDetailsOpen: true,
      selectedItem: item,
    });
  };
  handleChange = () => { this.setState({ 
    jiraChecked: !this.state.jiraChecked });   
    this.savePreferences('jiraChecked', !this.state.jiraChecked ); 
  };

  handleSLABtnClick = () => { 
    this.setState({ showAllSLA: !this.state.showAllSLA });  
    //this.savePreferences('showAllSLA', !this.state.showAllSLA ); 
  };

  handlePendingBtnClick = () => { 
    this.setState({ showAllPending: !this.state.showAllPending });  
    //this.savePreferences('showAllPending', !this.state.showAllPending ); 
   };


  savePreferences = (prop:any, value:any) => { 
 
    const {jiraChecked /*sortState, orderOfSorts, showEmptyUserTables, selectedTicketTypeFilter, searchString, , showAllSLA, showAllPending*/ } = this.state;
 
    localStorage.setItem('preferences', JSON.stringify({
     // sortState,
     // orderOfSorts,
     // showEmptyUserTables,
     // selectedTicketTypeFilter,
     // searchString,
      jiraChecked,
     // showAllSLA,
     // showAllPending,
      [prop] : value
    }));
  }
  
  render() {
    const emptyRender: React.FunctionComponent = () => {
      return (
        <div className="table-empty-container well text-center">
          <div className="empty-table-message">
            <FaBicycle />
            <div>Neat! No items to show.</div>
          </div>
        </div>
      );
    };
    const { views, client, data } = this.props;

    if(!data) {
      return <></>
    }

    const {
      components,
      tableConfig,
      showEmptyUserTables,
      isEmailDraftOpen,
      isDetailsOpen,
      selectedItem,
      showAllSLA,
      showAllPending
    } = this.state;

    const hideEmpty =
      !showEmptyUserTables && views && views.includes("USERASSIGNED");

    const filteredData = this.doSort(this.searchFilter(data, views));

    const limitedPendingData = filteredData['FTSA'] ? filteredData['FTSA'].filter((obj: any) => {
      let twoWeeks = moment().add(2, "weeks");
      let startDate = moment(obj.FTSEventStart);
      if (obj.ftsNextAction !== "PENDING EVENT") return true; // To make sure only Pending Events get filtered
      return moment(startDate).isBefore(twoWeeks);
    }) : [];

    const columns = (v: string) => {
      if ((v !== 'SLA' && v !== 'FTSA') || (v === 'SLA' && showAllSLA) || (v === 'FTSA' && showAllPending)) { return filteredData[v] ? filteredData[v] : [] }
      else if (v === 'SLA' && !showAllSLA) { return filteredData[v] ? filteredData[v].slice(0, 10) : [] }
      else if (v === 'FTSA' && !showAllPending) { return filteredData[v] ? limitedPendingData : [] }
    }

    return (
      <React.Fragment>
        <div className="table-filters">
          {/* <div className="dropdown-wrapper">
                            <ReactDropdownSelect placeholder="Limit tables to..." onChange={this.selectedTableLimitChange}  className="select" options={pageOptions}/>
                        </div> */}
          <div className="table-wrapper-search">
            <input
              id="searchBoxInput"
              type="text"
              className="searchbox"
              placeholder={`Search all (ie. 00123123, Joe Tester, Account Name)`}
              //  value={this.state.searchString}
              onChange={(ev) => {  
                // this.setState({
                //   searchString: ev.target.value,
                // });
                this.updateTextSearchDebounced(ev.target.value.toLowerCase()); 
              }}
            />
          </div>
          <div className="table-wrapper-filter check_filter">
            <CheckboxFilter
              selected={this.state.selectedTicketTypeFilter}
              onChange={this.updateFilterSearch}
              options={["Core", "A&I", "Atlas", "Realm"]}
              idName='CheckboxFilter'
            ></CheckboxFilter>
            <label className={'filterLink aFilterLink'} onClick={this.updateFilterSearchAll} id='SelectAllFilter'>
              {this.state.selectedTicketTypeFilter.length > 3 ? 'Deselect All' : 'Select All'}
            </label>
          </div>
          {(data || this.props.data) &&
            views &&
            views.includes("FSA") &&
            views.includes("FSW") &&
            views.includes("FTSA") && (
              <div className="email-draft-box">
                <Button id='generateFtsEmailDraftButton'
                  variant="primary"
                  title={"Generate FTS Email Draft"}
                  onClick={(ev: any) => this.onGenerateFTSEmailDraftOpen()}
                >
                  Generate FTS Email Draft
                </Button>
              </div>
            )}
          {(data || this.props.data) && views && views.includes("USERASSIGNED") && (
            <div className="email-draft-box">
              <Button
                id="HideShowEmpty"
                variant="primary"
                title={showEmptyUserTables ? "Hide Empty" : "Show Empty"}
                onClick={this.swapShowEmptyUserTables}
              >
                {showEmptyUserTables ? "Hide Empty" : "Show Empty"}
              </Button>
            </div>
          )}
        </div>
        {this.state.searchString !== "" &&
          <div className='search-term'>
            <label id="showingResultsLabel">
              Showing search results for "{this.state.searchString}"
            </label>
          </div>
        }
        <div className="tables-wrapper">
          {filteredData &&
            views.map((v: string, idx: number) => {
              if (!filteredData[v]) {
                return ""; // Loading
              }
              if (
                hideEmpty &&
                filteredData[v] &&
                filteredData[v].length === 0
              ) {
                return ""; //Empty
              }
              const config = tableConfig[v];
              const tableResizer = v === "ACTS" || v === "WAIT";
              console.log("Rendering TableWrapper");
              return (
                <div className="table-wrapper" key={"table-" + idx}>
                  <div className="table-wrapper-header">
                    <div className="table-wrapper-title" id={v + '-title'}>
                      {config.title}
                    </div>
                    <div className={css`padding-top:8px;`}>
                      <Badge
                        id={v + '-badge'}
                        className={(filteredData[v].length === data[v].length) ? "title-count-badge badge-lightgray" : "badge-limited-highlight"}>
                        {filteredData[v].length === data[v].length ? data[v].length : filteredData[v].length + '/' + data[v].length}
                      </Badge>
                    </div>
                    {(v === 'SLA') ?
                      <div className={css`padding-top:8px; padding-left:17px;`}>
                        <Badge id='TCName' variant='blue' className="badge-blue">
                          {'TC : ' + this.props.TC}
                        </Badge>
                      </div> :
                      <></>}
                    {(v === 'FTSA') &&
                      <div className={css`padding-top:8px; padding-left:17px;`}>
                        <Badge id='DataRange' variant='blue' className="badge-blue">
                          {showAllPending ? "SHOWING ALL DATA" : "SHOWING DATA FOR NEXT TWO WEEKS"}
                        </Badge>
                      </div>}
                    {config.title.includes("Other Cases Waiting on Me") &&
                      <div className={"checkbox-filter checkBoxJira"}>
                        <Checkbox
                          id='jiraCheckBox'
                          className={"checkbox"}
                          label='Show JIRA and HELP Tickets'
                          onClick={this.handleChange}
                          checked={this.state.jiraChecked}>
                        </Checkbox>
                      </div>
                    }
                    {this.props.displayRecommendation && idx === 0 ?
                      <div className={recommendationBadgeStyle}>
                        <Badge variant="blue">
                          You have a new ticket recommendation
                        </Badge>
                      </div>
                      : null}
                    {!tableResizer && (
                      <>
                        <BaseTable
                          rowKey={v}
                          className="table non-scrolling-table"
                          data={columns(v)} //safe filter
                          width={1920}
                          rowHeight={75}
                          maxHeight={10000}
                          components={components}
                          rowClassName={this.rowClassName}
                          emptyRenderer={emptyRender}
                          sortState={
                            this.state.sortState[v] ? this.state.sortState[v] : {}
                          }
                          onColumnSort={(data) => this.onColumnSort(data, v)}
                        >
                          {config.columns.map((c: any) => (
                            <Column {...c} />
                          ))}
                        </BaseTable>
                        {(v === 'SLA' && filteredData[v].length > 10) &&
                          <div className="div-show-all-sla">
                            <Button
                              onClick={this.handleSLABtnClick}
                              className='button-show-all-sla'
                            >
                              {this.state.showAllSLA ? "Show less" : "Show All Cases"}
                            </Button>
                          </div>
                        }
                        {(v === 'FTSA' && filteredData[v].length > limitedPendingData.length) &&
                          <div className="div-show-all-sla">
                            <Button
                              onClick={this.handlePendingBtnClick}
                              className='button-show-all-sla'
                            >
                              {this.state.showAllPending ? "Show less" : "Show All"}
                            </Button>
                          </div>
                        }
                      </>
                    )}
                    {tableResizer && (
                      <BaseTableResizer
                        config={config}
                        data={filteredData[v] ? filteredData[v] : []} //safe filter
                        components={components}
                        sortState={
                          this.state.sortState[v] ? this.state.sortState[v] : {}
                        }
                        onColumnSort={(data) => this.onColumnSort(data, v)}
                      ></BaseTableResizer>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        {isDetailsOpen && (
          <ActionModal
            stitchClient={client}
            selectedItem={selectedItem}
            callback={this.onDetailsModalClose}
          ></ActionModal>
        )}
        {isEmailDraftOpen && (
          <DraftModal
            stitchClient={client}
            callback={this.onGenerateFTSEmailDraftClose}
          ></DraftModal>
        )}
      </React.Fragment>
    );
  }
}
