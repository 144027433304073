import React from "react";
import "./Carousel.css";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Card from "@leafygreen-ui/card";
import { FaBicycle } from "react-icons/fa";

type CarouselProps = {
    infinite: boolean;
    slidesToShow: number;
    slidesToScroll: number;
    children: any;
    visible: boolean;
}

type CarouselState = {
    canNext: boolean;
    canPrev: boolean;
    start: number;
    carouselLeftClass: string;
    carouselRightClass: string;
}


class Carousel extends React.Component<CarouselProps, CarouselState> {
    static defaultProps = {
        count: 6
    }

    constructor(props: any) {
        super(props);

        this.state = {
            start: 0,
            canNext: false,
            canPrev: false,
            carouselLeftClass: "carousel-left",
            carouselRightClass: "carousel-right"
        }
    }

    static getDerivedStateFromProps(props: CarouselProps, state: CarouselState): CarouselState {
        let myState = state;
        // fix for if children change to less than the number needed for current page
        if (props.children && (props.children.length < state.start)) {
            const page = Math.ceil(props.children.length / props.slidesToShow);
            const start = (page - 1) * props.slidesToShow;
            myState.start = start;
            myState.canNext = (start - props.slidesToScroll) >= 0;
            myState.canPrev = (start + props.slidesToScroll) < props.children.length;
        }
        return myState;
    }

    /** This is called during the button click. So it needs to know if the NEXT time after the current time, can it be clicked */
    getCanNextGetNext = (start: number): boolean => {
        return ((this.props.children !== undefined)
            && (start + this.props.slidesToScroll) < this.props.children.length);
    }
    /** This is called during the button click. So it needs to know if the NEXT time after the current time, can it be clicked */
    getCanPrevGetPrev = (start: number): boolean => {
        return (start - this.props.slidesToScroll) >= 0;
    }
    canGoNext = (): boolean => {
        return ((this.props.children !== undefined)
            && (this.state.start + this.props.slidesToScroll) < this.props.children.length);
    }
    canGoPrev = (): boolean => {
        return this.state.start > 0;
    }

    next = () => {
        let start = this.state.start;
        if (this.props.infinite) {
            start = this.canGoNext() ? start + this.props.slidesToScroll : 0;
        } else if (this.canGoNext()) {
            start = start + this.props.slidesToScroll;
        }

        this.setState(this.calcAll({ start: start }))
    };

    prev = () => {
        console.log(this.state);
        let start = this.state.start;
        if (this.props.infinite) {
            start = this.canGoPrev() ? start - this.props.slidesToScroll : Math.floor(this.props.children.length / this.props.slidesToShow) * this.props.slidesToShow;
        } else if (this.canGoPrev()) {
            start = start - this.props.slidesToScroll;
        }
        this.setState(this.calcAll({ start: start }))
    };
    changeStart = (i: number) => {
        this.setState(this.calcAll({ start: i * this.props.slidesToScroll }))
    }

    calcAll = (s: { start: number }) => {
        if (!this.props.infinite) {
            Object.assign(s, {
                canNext: this.getCanNextGetNext(s.start),
                canPrev: this.getCanPrevGetPrev(s.start)
            })
        }
        return s;
        //this.setState((state) => (s));
    }

    render() {
        const { slidesToShow, slidesToScroll, children, visible, infinite } = this.props;

        const carouselLeftClass = "carousel-left" + (!infinite && this.getCanPrevGetPrev(this.state.start) ? "" : " disabled");
        const carouselRightClass = "carousel-right" + (!infinite && this.getCanNextGetNext(this.state.start) ? "" : " disabled");
        const items = children ? React.Children.toArray(children) : [];

        const style = {
            display: visible ? '' : 'none'
        }

        return (
            <div className="carousel row flex-center-all" style={style}>

                {items && items.slice(this.state.start, (this.state.start + slidesToShow)).map((item: any, i: number) => (
                    <div key={"carousel-item-" + i} className="column carousel-item">
                        {/* <TCHelperCard item={item}/> */}
                        {item}
                    </div>
                ))}
                {items && items.length <= 0 && <Card className="no-data"><div className="empty-message"><FaBicycle className="bike" /><div>No items to show.<br /> Maybe change your filters!</div></div></Card>}
                <div className={carouselLeftClass} onClick={this.prev} ><MdChevronLeft /></div>
                <div className={carouselRightClass} onClick={this.next}><MdChevronRight /></div>
                {slidesToScroll === slidesToShow && (<div className="dots">
                    {[...Array(Math.ceil(items.length / slidesToShow))].map((e, i) => (
                        <div className={"carousel-dot" + (this.state.start / slidesToShow === i ? " selected" : "")}
                            key={"carousel-dot-" + i} onClick={() => this.changeStart(i)}></div>
                    ))}
                </div>)}
            </div>
        )
    }
}

export default Carousel;