import React, { useState } from "react";
import Modal from '@leafygreen-ui/modal';
import Toggle from '@leafygreen-ui/toggle'
import "./ActivityMonitor.css";

type Props = {
    onClose: Function;
    onRollingEvents: Function;
    events:  Array<any>;
}

const ActivityMonitor: React.FunctionComponent<Props> = (props) => {

    const [showing, setShowing] = useState(new Map());
    const [copied, setCopied] = useState(false);
    const [rolling, setRolling] = useState(true);

    const _onClose = (open: boolean) => {
        if (props.onClose) props.onClose();
    }

    const _onRollingEvents = () => {
        if (props.onRollingEvents) {
            let r = props.onRollingEvents();
            setRolling(r);
        }
    }

    const json = {
        replacer: function (match: any, pIndent: any, pKey: any, pVal: any, pEnd: any) {
            var key = '<span class=json-key>';
            var val = '<span class=json-value>';
            var str = '<span class=json-string>';
            var r = pIndent || '';
            if (pKey)
                r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
            if (pVal)
                r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>';
            return r + (pEnd || '');
        },
        prettyPrint: function (obj: any) {
            var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;
            return JSON.stringify(obj, null, 3)
                .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
                .replace(/</g, '&lt;').replace(/>/g, '&gt;')
                .replace(jsonLine, json.replacer);
        }
    };

    const updateMap = (k: any, v: any) => {
        let s = !showing.get(k)
        showing.set(k, s);
        let newMap = new Map(showing)
        setShowing(newMap);
    }

    const copyToClipboard = (event: any) => {
        var textField = document.createElement('textarea')
        textField.textContent = JSON.stringify(event, undefined, 2);
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()

        setCopied(true);

        setTimeout(() => {
            setCopied(false)
        }, 2000)
    }

    const items = () => (
        <>
        {/* <div className='container-col'>
        <div className='end'>
             { rolling && `${props.events.length} of 1000`} 
        </div>
        </div> */}
   
        <ul className="aul">
            {props && props.events != null && props.events.map((event: any, idx) => (
                <li key={idx} className="ali">
                    <div>{`${("00" + (idx + 1)).slice(-3)}. `}{event?.sfdc?.CaseNumber ? event.sfdc.CaseNumber : event.key}&nbsp;&nbsp;{event.changeStreamDate.toLocaleString()}&nbsp;&nbsp;<button data-id={idx} onClick={() => updateMap(idx, true)}> {showing.get(idx) ? 'hide' : 'show'}</button>&nbsp;{showing.get(idx) ? <><button onClick={() => copyToClipboard(event)}>copy</button> </> : <span></span>}</div>
                    {showing.get(idx) &&
                        <>
                            <pre>
                                <code dangerouslySetInnerHTML={{
                                    __html: json.prettyPrint(event)
                                }} />
                            </pre>
                        </>
                    }
                </li>
            ))}
        </ul>
       </>
    );

    return <Modal
        open={true}
        setOpen={_onClose}>
        <div className='container'>
            <header>
                <h3>Activity Monitor ({props.events.length})</h3>
            </header>
            <div className='inner'>
                Rolling Events
            </div>&nbsp;
                  <Toggle className='inner'
                size='small'
                onChange={(event) => _onRollingEvents()}
                checked={rolling} />
        </div>
        <div className="box">
            {copied && <span className="copy">Event Copied!</span>}
        </div>
        <div className='container-col'>
        <div className='end'>
             { rolling && `${props.events.length} of 500`} 
        </div>
        </div>
        <div className="content"> {items()} </div>
    </Modal>
}

export default ActivityMonitor;