/**
 * Takes a hub data item and adds 
 *      CALC_slaTimeStr,
 *      CALC_waitingTime,
 *      CALC_remainingSeconds,
 *      CALC_waitingSeconds
 * @param item 
 */
function addTimeCalcs(item : any) {
    let missed = false;
    let noFollowUp = false;
    let remainingSeconds = 0;
    let waitingSeconds = 0;
    let initialTotalSeconds = item.initialTotalSeconds;
    let followUpTotalSeconds = item.followUpTotalSeconds;
    // console.log(`initialTotalSeconds: ${initialTotalSeconds},
    // followUpTotalSeconds: ${followUpTotalSeconds}`);
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    // Active SLAs
    if (initialTotalSeconds && item.isSLA) {
        
        // days = Math.floor(initialTotalSeconds / 86400); // counting down
        // hours = Math.floor((initialTotalSeconds - (days * 86400)) / 3600);
        // minutes = Math.floor(((initialTotalSeconds - (days * 86400)) - hours * 3600) / 60);
        // seconds = Math.floor(initialTotalSeconds % 60);
  
        remainingSeconds = initialTotalSeconds; // for sorting, counting down
        waitingSeconds = item.days * 86400 + item.hours * 3600 + item.minutes * 60; // for sorting, counting up
        missed = initialTotalSeconds < 0;

        ({days, hours, minutes, seconds} = millisecondsToTime(initialTotalSeconds));


        // Follow Up SLAs (FTS/UNA/etcs)
    } else if (followUpTotalSeconds && item.status === 'In Progress') {
       
        // days = Math.floor(followUpTotalSeconds / 86400); // counting down
        // hours = Math.floor((followUpTotalSeconds - (days * 86400)) / 3600);
        // minutes = Math.floor(((followUpTotalSeconds - (days * 86400)) - hours * 3600) / 60);
        // seconds = Math.floor(followUpTotalSeconds % 60);
  
        remainingSeconds = followUpTotalSeconds; // for sorting, counting down
        waitingSeconds = item.days * 86400 + item.hours * 3600 + item.minutes * 60; // for sorting, counting up
        missed = followUpTotalSeconds < 0;

        ({days, hours, minutes, seconds}  = millisecondsToTime(followUpTotalSeconds));

        // For Non-FollowUp / Old MMSSUPPORT ticket / waiting time - counting up
    } else {
        days = item.days;
        hours = item.hours;
        minutes = item.minutes;
        seconds = item.seconds
        waitingSeconds = days * 86400 + hours * 3600 + minutes * 60; // for sorting, counting up
        noFollowUp = true;
    }

    let waitingTimeStr = "";
    let slaTimeStr = "";
    if (missed) {
        slaTimeStr = 'MISSED';
    } else if (noFollowUp) {
        slaTimeStr = 'N/A';
    } else if (Math.abs(days) > 0) {
        slaTimeStr += days + 'd ';
        slaTimeStr += hours + 'h';
    } else {
        slaTimeStr = slaTimeStr + hours + 'h ' + minutes + 'm';
    }

    if (Math.abs(item.days) > 0) {
        let days = item.days;
        let hours = item.hours;
        // let minutes = ticket.minutes;
        waitingTimeStr += days + 'd ';
        waitingTimeStr += hours + 'h';
    } else {
        // let days = ticket.days;
        let hours = item.hours;
        let minutes = item.minutes;
        waitingTimeStr = waitingTimeStr + hours + 'h ' + minutes + 'm';
    } 

    // console.log(`slaTimeStr: ${slaTimeStr}, waitingTimeStr: ${waitingTimeStr}`);

    return Object.assign(item, {
        CALC_slaTime: { 
           /* display: (hours === 0) ? `${slaTimeStr.replace('0h','')} ${seconds}s` : slaTimeStr, */ // dont want to mess with original time string yet
            CALC_remainingSeconds: remainingSeconds,
            isFollowUp: (followUpTotalSeconds && item.status === 'In Progress'),
            days : days, 
            hours: hours, 
            minutes: minutes, 
            seconds: seconds
        },
        CALC_waitingTimeObj: {
            days: item.days,
            hours: item.hours,
            minutes: item.minutes,
            seconds: item.seconds ?? 60 - seconds
        },
        CALC_slaTimeStr: slaTimeStr,
        CALC_waitingTime: waitingTimeStr,
        CALC_remainingSeconds: remainingSeconds,
        CALC_waitingSeconds: waitingSeconds,
        CALC_initialResponseSLAHours: (item.totalMinutes ? item.totalMinutes / 60 : 0)
    });
}


function millisecondsToTime(ms: number) {

    let days = Math.floor(ms / 86400);
    ms -= days * 86400;

    // calculate (and subtract) whole hours
    let hours = Math.floor(ms / 3600) % 24;
    ms -= hours * 3600;

    // calculate (and subtract) whole minutes
    let minutes = Math.floor(ms / 60) % 60;
    ms -= minutes * 60;

    // what's left is seconds
    let seconds = ms % 60; 

    return { days: days,
             hours: hours,
             minutes: minutes,
             seconds: seconds }; 
}

/**
 * Takes a hub view and a hub data item and adds the SLA state.
 * Only 'SLA' 'FSA' 'UNA' 'UNAS'  'USERASSIGNED' are parsed.
 * @param type 
 * @param item 
 */
function addSLAState(type : string, item : any) {
    if (type === 'SLA' || type === 'FSA' || type === 'UNA' || type === 'UNAS' || type === 'USERASSIGNED' || type === 'TCW') {
        let isSLA = item.isSLA;
        //let isFTS = item.isFTS;
        let initialTotalSeconds = item.initialTotalSeconds;
        let followUpTotalSeconds = item.followUpTotalSeconds;
        let one_hour = 3600;
        let two_hour = 7200;
        let four_hour = 14400;
        let eight_hour = 28800;
        let SLA_state = 'sla_future';
        let SLA_state_color = 'lightgray';

        if ((isSLA === true && initialTotalSeconds && initialTotalSeconds < one_hour) || (followUpTotalSeconds && followUpTotalSeconds < one_hour)) {
            SLA_state = "sla_warning";
            SLA_state_color = "red";
        } else if ((isSLA === true && initialTotalSeconds && initialTotalSeconds < two_hour) || (followUpTotalSeconds && followUpTotalSeconds < two_hour)) { // 2 hours
            SLA_state = "sla_upcoming";
            SLA_state_color = "yellow";
        } else if ((isSLA === true && initialTotalSeconds && initialTotalSeconds < four_hour) || (followUpTotalSeconds && followUpTotalSeconds < four_hour)) { // 4 hours
            SLA_state = "sla_later";
            SLA_state_color = "green";
        } else if ((isSLA === true && initialTotalSeconds && initialTotalSeconds < eight_hour) || (followUpTotalSeconds && followUpTotalSeconds < eight_hour)) { // 8 hours
            SLA_state = "sla_later2";
            SLA_state_color = "green";
        } else {
            SLA_state = "sla_future";
            SLA_state_color = "lightgray";
        }
        // console.log(`state: ${state}`);
        return Object.assign(item, {
            CALC_slaState: SLA_state,
            CALC_slaColor: SLA_state_color
        });
    }
}

function addNextActionState(type : string, item : any) {
    let NA_state_color = 'lightgray';
    if (["FSA","FSW","FTSA"].includes(type)) {
        let ftsNextAction = item.ftsNextAction;
        if (ftsNextAction) {
            if(['AFFINITY AMER'].includes(ftsNextAction)) {
                NA_state_color = "yellow";
            } else if(["AFFINITY EMEA"].includes(ftsNextAction)) {
                NA_state_color = "green";
            } else if(["AFFINITY APAC"].includes(ftsNextAction)) {
                NA_state_color = "blue";
            } else if(["MONITOR CLUSTER","MONITOR HELP","RESPOND TO CUST","UPDATE JIRA TICKET","WORK"].includes(ftsNextAction)) {
                NA_state_color = "green-light";
            } else if(["PEND CUST INFO/LOGS","AWAITING CUST INFO/LOGS","MAINTENANCE","CLOSE/CONFIRM RESOLVED","PENDING EVENT"].includes(ftsNextAction)) {
                NA_state_color = "blue-light";
            }
        }
    }
    return Object.assign(item, {
        CALC_nextActionColor: NA_state_color
    });
}

/**
 * Takes a hub data item and adds a quick search, so filtering can be done simply as 'indexOf'
 * @param item 
 */
function addQuickSearch(item: any) {
    let quickSearch = '';

    quickSearch += item.id ? item.id.toLowerCase() : '';
    quickSearch += ';';
    quickSearch += item.company ? item.company.toLowerCase() : '';
    quickSearch += ';';
    quickSearch += item.assignee ? item.assignee.toLowerCase() : '';
    quickSearch += ';';
    quickSearch += item.desc ? item.desc.toLowerCase() : '';
    quickSearch += ';';
    quickSearch += item.description ? item.description.toLowerCase() : '';
    quickSearch += ';';
    quickSearch += (item.id && item.priority) ? 
        (item.id.indexOf('0') === 0 ? "s" : "p") + item.priority : '';
    quickSearch += ';';
    quickSearch += (item.lastPublicCommentUser && item.lastPublicCommentUser.CreatedByName) ?
        item.lastPublicCommentUser.CreatedByName.toLowerCase() : '';
    quickSearch += ';';
    quickSearch += (item.comps && item.comps.length > 0) ? item.comps.join(";") : '';
    quickSearch += ';';
    quickSearch += (item.components && item.components.length > 0) ? item.components.join(";") : '';
    quickSearch += ';';
    quickSearch += (item.triageComps && item.triageComps.length > 0) ? item.triageComps.join(";") : '';
    quickSearch += ';';
    quickSearch += (item.triageComponents && item.triageComponents.length > 0) ? item.triageComponents.join(";") : '';
    quickSearch += ';';
    return Object.assign(item, {quickSearch: quickSearch})
}

/**
 * Takes a hub data object and extends it with time CALCS, sla, and quick search data.
 * @param data 
 */
export default function HubDataExtension(data : any) : any[] {
    for (let prop in data) {
        if (data[prop] && data[prop].length > 0) {
            data[prop].forEach((item : any) => {
                addTimeCalcs(item);
                addSLAState(prop, item);
                addNextActionState(prop, item);
                addQuickSearch(item);
            });
        }
    }
    return data;
}