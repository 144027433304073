import React from 'react';

type Props = {
    timestamp: Date,
    days: number,
    hours: number,
    minutes: number,
    seconds: number
}

type State = {
    display: string
}

const MINUTE: number = 0;
const HOUR: number = 1
const DAY: number = 2;

class ElapsedTime extends React.Component<Props, State> {

    interval: any = undefined;

    selectedInterval: any;
    /* timestamp: any; */
    display = '';
    days = 0;
    hours = 0;
    minutes = 0;
    seconds = 0;

    constructor(props: any) {
        super(props);
       // this.timestamp = this.props.timestamp;

        //TODO: need to fix this from being a string
        // if ((this.timestamp instanceof Date) !== true) {
        //     this.timestamp = new Date(this.timestamp)
        // }

        // let diff = Math.abs((new Date().getTime() - this.timestamp.getTime()) / 1000)

        // var d = Math.floor(diff / (3600 * 24));
        // var h = Math.floor(diff % (3600 * 24) / 3600);
        // var m = Math.floor(diff % 3600 / 60);
        // var s = Math.floor(diff % 60);

        this.days = (this.props.days ?? 0);
        this.hours = (this.props.hours ?? 0);
        this.minutes = (this.props.minutes ?? 0);
        this.seconds = (this.props.seconds ?? 0);

        // this.days = this.days + d;
        // this.hours = (this.hours + 60 + h) % 60;
        // this.minutes = (this.minutes + 60 + m) % 60;
        // this.seconds = (this.seconds + 60 + s) % 60; 
        

        if (this.days > 0) {
            this.display = `${this.days}D ${this.hours}H`;
        } else {
            this.display = `${this.hours}H ${this.minutes}M`;
        }

        this.state = {
            display: this.display
        };
    }

    componentDidMount() {

        this.selectedInterval = this.getIntervalType()[MINUTE]; 
        this.startInterval();
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
            console.log('clear interval');
        }
    }

    startInterval() {

        this.interval = setInterval(() => {

           // console.log(this.seconds);

            if (this.seconds < 59) {
                this.seconds += this.selectedInterval.seconds;
            } else {
                this.seconds = 0;
                this.minutes += 1;

                if (this.minutes === 60) {
                    this.minutes = 0;
                    this.hours += 1;

                    if (this.hours === 24) {
                        this.hours = 0;
                        this.days += 1;
                    }
                }
            }

            if (this.days > 0) {
                this.display = `${this.days}D ${this.hours}H`;
            } else {
                this.display = `${this.hours}H ${this.minutes}M`;
            }
    
            this.setState({ display: this.display });
 
        }, this.selectedInterval.milliseconds);
    }

    changeInterval() {
        clearInterval(this.interval);
        this.startInterval();
    }

    getIntervalType(): { [key: number]: any } {
        return {
            0: {
                key: MINUTE,
                seconds: 1,
                milliseconds: 1000  // every second
            },
            1: {
                key: HOUR,
                seconds: 5,
                milliseconds: 1000 * 5 // every 5 seconds
            },
            2: {
                key: DAY,
                seconds: 10,
                milliseconds: 1000 * 10  // every 10 second
            }
        }
    };

    render() {
        const { display } = this.state;
        return ( 
                 <div>{display} </div>
        );
    }
}

export default ElapsedTime;