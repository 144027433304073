import { StitchAppClient, RemoteMongoClient /*, RemoteMongoDatabase*/ } from 'mongodb-stitch-browser-sdk';

export default class Watcher {
    support?: any;
    data?: any;

    changeStreamReviews: any = null;
    changeStreamIssues: any = null;
    changeStreamMyCases: any = null;
   
    
    public static view:string = '';

    constructor(client?: StitchAppClient, data?: any) {

        if (client) {
            this.support = client.getServiceClient(RemoteMongoClient.factory, "mongodb-atlas").db('support');
            this.data = data;
        } else {
            console.log('nope, no watching today')
        }
    }

    private getUSERIssuesFilter = (user: any): any => {

        let user_10gen = user + '@10gen.com';
        let user_mongoDB = user + '@mongodb.com';
        let userGroup = {
            "$in": [user, user_10gen, user_mongoDB]
        };
        let jira_user_query = {
            "fullDocument.jira.fields.assignee.name": userGroup
        };

        let sfdc_user_query = {
            "fullDocument.sfdc.OwnerSSOId": userGroup
        };

        // Basic query and key
        // let jira_query = {
        //     "$and": [{
        //         "fullDocument.jira.fields.status.name": {
        //             "$nin": ["Closed"]
        //         }
        //     },
        //         jira_user_query,
        //     {
        //         "fullDocument.deleted": false
        //     }
        //     ]
        // };

        let sfdc_query = {
            "$and": [{
                "fullDocument.sfdc": {
                    "$exists": true
                }
            },
                // {
                //     "fullDocument.sfdc.Status": {
                //         "$nin": ["Closed"]
                //     }
                // },
                sfdc_user_query,
                // {
                //     "fullDocument.deleted": false
                // }
            ]
        };

        // let combo_query = {
        //     "$or": [jira_query, sfdc_query]
        // };

        let combo_query = {
            "$or": [jira_user_query, sfdc_query]
        };

        return combo_query;
    }

    private generateIssuesFilter = (): any => {

        /*
        let jira_sla_query = {
            "$or": [{
                "fullDocument.sla.expireAt": {
                    "$ne": null
                }
            }, {
                "fullDocument.sla.initial.expireAt": {
                    "$ne": null
                }
            }]
        };
        
        let sfdc_sla_query = {
            "$or": [{
                "fullDocument.sla.expireAt": {
                    "$ne": null
                }
            }, {
                "fullDocument.sla.initial.expireAt": {
                    "$ne": null
                }
            }]
        };
        */

        // let jira_fts_query = {
        //     "fullDocument.jira.fields.labels": "fs"
        // };
        // let sfdc_fts_query = {
        //     "fullDocument.sfdc.Follow_The_Sun__c": true
        // };

        // let jira_una_query = {
        //     'fullDocument.jira.fields.assignee': null
        // };
        // let sfdc_una_query = {
        //     'fullDocument.sfdc.OwnerType': {
        //         '$ne': "Standard"
        //     }
        // };


        // // Basic query and key
        // let jira_query = {
        //     "$and": [{
        //         "fullDocument.jira.fields.issuetype.name": {
        //             "$nin": ["Tracking"]
        //         }
        //     },
        //     {
        //         "fullDocument.jira.fields.project.key": {
        //             "$in": ["SUPPORT", "PARTNER"]
        //         }
        //     },
        //     {
        //         "fullDocument.jira.fields.status.name": {
        //             "$in": ["Open", "Reopened", "In Progress", "Waiting For User Input", "In Code Review"]
        //         }
        //     }
        //     // ,
        //     // {
        //     //     "$or": [ jira_sla_query,  jira_fts_query, jira_una_query]
        //     // }
        //     //,
        //     // {
        //     //     "fullDocument.deleted": false
        //     // }
        //     ]
        // };
        // let sfdc_query = {
        //     "$and": [
        //     {
        //         "fullDocument.sfdc": {
        //             "$exists": true
        //         }
        //     }
        //     //,
        //     // {
        //     //     "fullDocument.sfdc.Status": {
        //     //         "$in": ["New", "Open", "In Progress", "Reopened"]
        //     //     }
        //     // },
        //     // {
        //     //     "$or": [ /*sfdc_sla_query,*/ sfdc_fts_query /*, sfdc_una_query */]
        //     // }
        //     // ,
        //     // {
        //     //     "fullDocument.deleted": false
        //     // }
        //     ]
        // };

        // let combo_query = {
        //     "$or": [jira_query, sfdc_query]
        // };


        // return combo_query;

        //return sfdc_query;

        return {
            "fullDocument.sfdc": {
                "$exists": true
            }
        }
    }

    private generateReviewsFilter = (): any => {

        //let issuesQuery;

        // let review_query = {
        //     "done": false, 
        //     "status": {
        //         "$ne": "approved"
        //     }
        // };

        let review_query = {
            "done": false
        };

        //let review_query = {};

        return this.support.collection('reviews').find(review_query).toArray().then((reviews: any[]) => {
            let reviewMap: any = new Map();

            reviews.forEach(review => {
                reviewMap[review.key] = review;
            });

            return reviewMap;

        }).then((reviewMap: any) => {
            // issuesQuery = {
            //     "$or": [{
            //         "fullDocument.sfdc.CaseNumber": {
            //             "$in": Object.keys(reviewMap)
            //         }
            //     },
            //     {
            //         "fullDocument.jira.key": {
            //             "$in": Object.keys(reviewMap)
            //         }
            //     }]
            // };

            // return {
            //        "fullDocument.sfdc.CaseNumber": {
            //        "$in": Object.keys(reviewMap)
            //     }};


            return {

                "$or": [
                    {
                        "fullDocument.sfdc.CaseNumber": {
                            "$in": Object.keys(reviewMap)
                        }
                    },
                    { "fullDocument.done": { "$in": [true, false] } }
                ]
            };
        });

    }

    private getIssueById = (key: string) => new Promise((resolve, reject) => {

        this.support.collection('issues').findOne(
            { "$or": [{ "sfdc.CaseNumber": key }, { "jira.id": key }] }
        ).then((issue: any) => {
            if (issue) {
                return resolve(issue);
            } else {
                reject(`No document matches the provided issue key: ${key}.`)
            }
        })
            .catch((err: any) => { return reject(`Failed to find issue document: ${err}`) });
    });

    async watchTrafficCop(callback: Function) {

        let issuesFilter = this.generateIssuesFilter();
        //let reviewsFilter = await this.generateReviewsFilter();

        //console.log(issuesFilter);
        //console.log(reviewsFilter);

        let issues = this.getCollection('issues');
        let reviews = this.getCollection('reviews');

        // const ts:any = Math.floor(Date.now() / 1000) 
        this.changeStreamIssues = await issues.watch(issuesFilter, {
            startAtOperationTime: new Date(),
            fullDocument: 'updateLookup'
        });

        this.changeStreamReviews = await reviews.watch({}); //reviewsFilter);

        if (!this.changeStreamIssues || !this.changeStreamReviews) { 
           // console.warn("issues/reviews change stream is null, possible causes: switching tabs back and forth really fast...");

            if(Watcher.view !== 'TC') {  
               // console.warn(`Setting up TC but Tab is on ${Watcher.view} closing issues change stream.`);
                this.close();
                return; 
            }  
        }

        this.changeStreamIssues.onNext((changeEvent: any) => {

            // determine which data collection that belong to the case
            let dataSources: any = [];

            if (this.isSLA(changeEvent.fullDocument)) {
                dataSources.push({ type: 'SLA', source: this.data.SLA });
            }

            if (this.isFSA(changeEvent.fullDocument)) {
                dataSources.push({ type: 'FSA', source: this.data.FSA });
            }

            if (this.isUNA(changeEvent.fullDocument)) {
                dataSources.push({ type: 'UNA', source: this.data.UNA });
            }

            // console.log('');
            // console.log('');
            // console.log('ISSUES: ' + changeEvent.fullDocument.sfdc.CaseNumber);
            // console.log(changeEvent); 
            // console.log('ISSUES COUNT: ' + dataSources.length);

            let copy = JSON.parse(JSON.stringify(changeEvent.fullDocument));
            delete copy.sfdc.Description;
            delete copy.sfdc.Case_Community_Link__c;
            delete copy.sfdc.Internal_Notes__c;
            delete copy.sfdc.Case_Comments__r;
            delete copy.sfdc.comments;
            delete copy.sfdc.Histories;
            delete copy.sfdc.CaseMilestones;
            delete copy.sfdc.commentsChangeLog;
            delete copy.sfdc.Resolution__c;
            delete copy.sfdc.FTS_Comments__c;
            delete copy.karakuri;
            delete copy.Escalation_Team_Members__r;
            copy.changeStreamDate = new Date();

            // if(this.isSLA(changeEvent.fullDocument)) {
            //     copy.slaExpiresAt = changeEvent.fullDocument.sla?.initial?.expireAt;
            // }

            // if(this.isFSA(changeEvent.fullDocument)) {
            //     copy.slaExpiresAt = changeEvent.fullDocument.sla?.initial?.expireAt;

            //     if (changeEvent.fullDocument?.sla?.followUp?.expireAt) {
            //         copy.followUpExpireAt = changeEvent.fullDocument.sla.followUp.expireAt;
            //     } else if (changeEvent.fullDocument?.sla?.expireAt) {
            //         copy.followUpExpireAt = changeEvent.fullDocument.sla.expireAt;
            //     }
            // }

            callback({
                operation: 'log',
                changeEvent: copy
            });

            dataSources.forEach((data: any) => {

                switch (changeEvent.operationType) {
                    case 'update':

                        let isDelete = (["New", "Open", "In Progress", "Reopened"].indexOf(changeEvent.fullDocument.sfdc.Status) === -1 || changeEvent.fullDocument.deleted);
                        // && (
                        /* sla *///(data.type === 'SLA' && !this.isSLA(changeEvent.fullDocument)) ||  
                        /* fts *///(data.type === 'FSA' && !this.isFSA(changeEvent.fullDocument)) || 
                        /* una *///(data.type === 'UNA' && !this.isUNA(changeEvent.fullDocument)));

                        let find = data.source.filter((obj: any) => {
                            return obj.id === changeEvent.fullDocument.sfdc.CaseNumber;
                        })

                        let found = find.length > 0;

                        if (isDelete && found) {

                            // delete

                            callback({
                                type: data.type,
                                operation: 'delete',
                                row: find[0],
                                changeEvent: changeEvent
                            });
                        } else {

                            // update or insert 

                            if (!isDelete) {

                                let row: any = found ? find[0] : {};
                                let operation: string = found ? 'update' : 'insert';
                                let timestamp: Date = found ? find[0].timestamp : new Date();

                                switch (data.type) {
                                    case 'SLA':
                                        row = this.formatSLAIssue(changeEvent.fullDocument);
                                        break;
                                    case 'FSA':
                                        row = this.formatFTSIssue(changeEvent.fullDocument);
                                        break;
                                    case 'UNA':
                                        row = this.formatIssue(changeEvent.fullDocument);
                                        break;
                                }

                                row.timestamp = timestamp;

                                callback({
                                    type: data.type,
                                    operation: operation,
                                    row: row,
                                    changeEvent: changeEvent
                                });
                            }
                        }
                        break;
                    case 'insert':

                        if ((["New", "Open", "In Progress", "Reopened"].indexOf(changeEvent.fullDocument.sfdc.Status) > -1 &&
                            (changeEvent.fullDocument.deleted === false)) && (
                            /* sla */ (data.type === 'SLA' && this.isSLA(changeEvent.fullDocument)) ||
                            /* fts */ (data.type === 'FSA' && this.isFSA(changeEvent.fullDocument)) ||
                            /* una */ (data.type === 'UNA' && this.isUNA(changeEvent.fullDocument)))) {

                            let inserted: any = {};

                            switch (data.type) {
                                case 'SLA':
                                    inserted = this.formatSLAIssue(changeEvent.fullDocument);
                                    break;
                                case 'FSA':
                                    inserted = this.formatFTSIssue(changeEvent.fullDocument);
                                    break;
                                case 'UNA':
                                    inserted = this.formatIssue(changeEvent.fullDocument);
                                    break;
                            }

                            inserted.timestamp = new Date();

                            callback({
                                type: data.type,
                                operation: changeEvent.operationType,
                                row: inserted,
                                changeEvent: changeEvent
                            });
                        }
                        break;
                }
            });


            //if(dataSources.length === 0 ) { 
            this.removeFromTrafficCop(changeEvent.fullDocument, callback);
            //} 
        });

        this.changeStreamReviews.onNext((changeEvent: any) => {

            // console.log('REVIEWS: ' + changeEvent.fullDocument.key);
            // console.log(changeEvent);
            // console.log('');

            changeEvent.fullDocument.changeStreamDate = new Date();

            callback({
                operation: 'log',
                changeEvent: changeEvent.fullDocument
            });

            switch (changeEvent.operationType) {
                case "update": {

                    let find = this.data.REVACT.filter((obj: any) => {
                        return obj.id === this.key(changeEvent.fullDocument); //.sfdc.CaseNumber;
                    })

                    if (find.length > 0) {

                        this.getIssueById(changeEvent.fullDocument.key).then((issue: any) => {

                            if (changeEvent.fullDocument.status === 'done' || changeEvent.fullDocument.done === true) {
                                // delete
                                callback({
                                    type: 'REVACT',
                                    operation: 'delete',
                                    row: find[0]
                                });
                            }

                            let timestamp: Date = find[0].timestamp;

                            find[0] = this.formatREVIssue(changeEvent.fullDocument, issue);
                            find[0].timestamp = timestamp;

                            // insert
                            callback({
                                type: 'REVACT',
                                operation: changeEvent.operationType,
                                row: find[0]
                            });

                        }).catch((err: any) => { console.error(`Failed to find issue document: ${err}`) });
                    } else {

                        // testing 
                        // duplicated

                        if (changeEvent.fullDocument.done === false && changeEvent.fullDocument.status !== 'approved') {

                            this.getIssueById(changeEvent.fullDocument.key).then((issue: any) => {

                                let inserted: any = this.formatREVIssue(changeEvent.fullDocument, issue);

                                // add timestamp
                                inserted.timestamp = new Date();

                                callback({
                                    type: 'REVACT',
                                    operation: 'insert',
                                    row: inserted
                                });

                            }).catch((err: any) => { console.error(`Failed to find issue document: ${err}`) });
                        }
                        // end
                    }

                }
                    break;
                case "insert":

                    if (changeEvent.fullDocument.done === false && changeEvent.fullDocument.status !== 'approved') {

                        this.getIssueById(changeEvent.fullDocument.key).then((issue: any) => {

                            let inserted: any = this.formatREVIssue(changeEvent.fullDocument, issue);

                            // add timestamp
                            inserted.timestamp = new Date();

                            callback({
                                type: 'REVACT',
                                operation: changeEvent.operationType,
                                row: inserted
                            });

                        }).catch((err: any) => { console.error(`Failed to find issue document: ${err}`) });
                    }

                    break;
            }
        });
    }

    async watchMyCases(user: string, callback: Function) {

        let filter = this.getUSERIssuesFilter(user);
        let issues = this.getCollection('issues');
        this.changeStreamMyCases = await issues.watch(filter);
 
        if (!this.changeStreamMyCases) { 
           // console.warn("My Cases change stream is null, possible causes: switching tabs back and forth really fast...");

            if(Watcher.view !== 'USER') {  
              //  console.warn(`Setting up User but Tab is on ${Watcher.view} closing issues change stream.`);
                this.close();
                return; 
            }  
        }

        this.changeStreamMyCases.onNext((changeEvent: any) => {

            let actions = [];

            switch (changeEvent.operationType) {

                case "insert": {

                    let inserted = this.formatIssue(changeEvent.fullDocument);

                    let type = '';

                    let status = this.getStatus(changeEvent.fullDocument);

                    if (this.isActive(changeEvent.fullDocument)) {
                        if (changeEvent.fullDocument.sfdc || this.key(changeEvent.fullDocument).substring(0, 7) === "SUPPORT") {
                            type = 'USERASSIGNED';
                        } else {
                            type = 'USERASSIGNEDOTHER';

                        }
                    } else if (status === "Waiting for Customer") {
                        type = 'USERWAITING';
                    } else if (status === "Resolved") {
                        type = 'USERRESOLVED';

                    } else if (status === "Waiting for bug fix" ||
                        status === "Waiting for Feature" ||
                        status === "Waiting for Development" ||
                        status === "Waiting For User Input" ||
                        status === "On Hold") {
                        type = 'USERONHOLD';
                    }

                    actions.push({
                        type: type,
                        operation: changeEvent.operationType,
                        row: inserted,
                        changeEvent: changeEvent
                    });
                }
                    break;
                case "update": {

                    let all = [{ 'type': 'USERASSIGNED', 'source': this.data.USERASSIGNED },
                    { 'type': 'USERASSIGNEDOTHER', 'source': this.data.USERASSIGNEDOTHER },
                    { 'type': 'USERWAITING', 'source': this.data.USERWAITING },
                    { 'type': 'USERRESOLVED', 'source': this.data.USERRESOLVED },
                    { 'type': 'USERREVIEWER', 'source': this.data.USERREVIEWER },
                    { 'type': 'USERONHOLD', 'source': this.data.USERONHOLD }];

                    let finds: any = [];


                    all.forEach(a => {
                        // eslint-disable-next-line
                        a.source.filter((obj: any) => {
                            if ((changeEvent.fullDocument.sfdc && obj.id === changeEvent.fullDocument.sfdc.CaseNumber) ||
                                (changeEvent.fullDocument.jira && obj.id === changeEvent.fullDocument.jira.key)) {
                                finds.push({ 'type': a.type, 'source': obj });
                            }
                        });
                    });

                    if (finds.length > 0) {

                        finds.forEach((find: any) => {

                            if (this.getStatus(changeEvent.fullDocument) === 'Closed' ||
                                changeEvent.fullDocument.deleted === true) {

                                actions.push({
                                    type: find.type,
                                    operation: 'delete',
                                    row: find.source
                                });
                            } else {
                                find.source = this.formatIssue(changeEvent.fullDocument)
                                actions.push({
                                    type: find.type,
                                    operation: changeEvent.operationType,
                                    row: find.source
                                });
                            }
                        });
                    }
                    break;
                }
            }

            console.log(actions);

            if (actions.length > 0) {
                callback({ 'actions': actions });
            }

        });
    }

    private removeFromTrafficCop(fullDocument: any, callback: Function) {

        let isDelete = (["New", "Open", "In Progress", "Reopened"].indexOf(fullDocument.sfdc.Status) === -1 || fullDocument.deleted);

        // check SLA's
        let find = this.data.SLA.filter((obj: any) => {
            return obj.id === fullDocument.sfdc.CaseNumber;
        })

        if (find.length > 0) {
            if (!this.isSLA(fullDocument) || isDelete) {
                callback({
                    type: 'SLA',
                    operation: 'delete',
                    row: find[0]
                });
            }
        }

        // check FSA's
        find = this.data.FSA.filter((obj: any) => {
            return obj.id === fullDocument.sfdc.CaseNumber;
        })

        if (find.length > 0) {
            if (!this.isFSA(fullDocument) || isDelete) {

                callback({
                    type: 'FSA',
                    operation: 'delete',
                    row: find[0]
                });
            }
        }

        // check UNA's
        find = this.data.UNA.filter((obj: any) => {
            return obj.id === fullDocument.sfdc.CaseNumber;
        })

        if (find.length > 0) {
            if (!this.isUNA(fullDocument) || isDelete) {
                callback({
                    type: 'UNA',
                    operation: 'delete',
                    row: find[0]
                });
            }
        }
    }

    private isSLA(fullDocument: any) {
        return (fullDocument.hasOwnProperty('sla') &&
            fullDocument.sla.hasOwnProperty('initial') &&
            fullDocument.sla.initial.hasOwnProperty('expireAt') &&
            fullDocument.sla.initial.hasOwnProperty('satisfiedAt') &&
            fullDocument.sla.initial.satisfiedAt === null);
        //this.hasAssignee(fullDocument));
    }

    private isFSA(fullDocument: any) {
        return fullDocument.sfdc?.Follow_The_Sun__c;
    }

    private isUNA(fullDocument: any) {
        // return ((fullDocument.sfdc.OwnerType !== 'Standard' ||
        //     (!this.hasAssignee(fullDocument) && fullDocument.sfdc.Status !== 'New')));

        // return ((fullDocument.sfdc.OwnerType !== 'Standard' ||
        // (!this.hasAssignee(fullDocument) && fullDocument.sfdc.Status !== 'New')));

        // return (fullDocument.sfdc.OwnerType !== 'Standard' && 
        //         !this.hasAssignee(fullDocument) && 
        //         fullDocument.sfdc.Status !== 'New'); 

        return fullDocument.sfdc.OwnerType !== 'Standard' &&
            !this.hasAssignee(fullDocument) &&
            fullDocument.sfdc.Status !== 'New' &&
            fullDocument.sla.initial.satisfiedAt !== null;
    }

    private getCollection(name: string) {
        return this.support.collection(name);
    }

    public close() {

        if (this.changeStreamIssues) {
            this.changeStreamIssues.close();
            this.changeStreamIssues = null;
        }

        if (this.changeStreamReviews) {
            this.changeStreamReviews.close();
            this.changeStreamReviews = null;
        }

        if (this.changeStreamMyCases) {
            this.changeStreamMyCases.close();
            this.changeStreamMyCases = null;
        }
    }

    private hasAssignee(fullDocument: any) {

        if (fullDocument.jira && fullDocument.jira.fields.assignee) {
            return true;
        } else if (fullDocument.sfdc && fullDocument.sfdc.OwnerSSOId) {
            return true;
        }

        return false;
    }

    private getStatus(fullDocument: any) {

        if (fullDocument.jira && fullDocument.jira.fields.status) {
            return fullDocument.jira.fields.status.name;
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.Status;
        }

        return '';
    }

    private created(fullDocument: any) {
        if (fullDocument.jira) {
            return fullDocument.jira.fields.created;
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.CreatedDate;
        }
    }

    private key(fullDocument: any) {
        if (fullDocument.key) {
            return fullDocument.key;
        } else if (fullDocument.jira) {
            return fullDocument.jira.key;
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.CaseNumber;
        }

        return null;
    }

    private priority(fullDocument: any) {
        if (fullDocument.jira) {
            return fullDocument.jira.fields.priority.id;
        } else if (fullDocument.sfdc) {
            if (fullDocument.sfdc.Severity__c && fullDocument.sfdc.Severity__c.length >= 2) {
                return parseInt(fullDocument.sfdc.Severity__c[1]);
            }
        }

        return 3; //Default Major
    }

    private assigneeDisplayName(fullDocument: any) {

        if (fullDocument.jira && fullDocument.jira.fields.assignee) {
            return fullDocument.jira.fields.assignee.displayName;
        } else if (fullDocument.sfdc && fullDocument.sfdc.OwnerSSOId) {
            return fullDocument.sfdc.OwnerName;
        }

        return null;
    }

    private reporterSummaryDescription(fullDocument: any) {
        if (fullDocument.jira) {
            return fullDocument.jira.fields.summary;
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.Subject;
        }
    }

    private project(fullDocument: any) {
        if (fullDocument.jira && fullDocument.jira.fields && fullDocument.jira.fields.customfield_10030) {
            return fullDocument.jira.fields.customfield_10030.name;
        } else if (this.isCloudProjectSFDCIssue(fullDocument)) {
            return fullDocument.sfdc.CloudProjectName;
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.ProjectName;
        } else {
            return ""; // We want a blank not undefined
        }
    }

    private company(fullDocument: any) {
        if (fullDocument.jira && fullDocument.jira.fields && fullDocument.jira.fields.customfield_10030) {
            return fullDocument.jira.fields.customfield_10030.name;
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.company;
        } else {
            return ""; // We want a blank not undefined
        }        
    }   

    private sf_project_id(fullDocument: any) {
        return fullDocument.sf_project_id;
    }

    private isFTS(fullDocument: any) {
        if (fullDocument.sfdc) {
            return fullDocument.sfdc.Follow_The_Sun__c;
        }
    }

    private product(fullDocument: any) {
        if (fullDocument.jira) {
            return ""; // This field is not populated for JIRA tickets
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.Product__c;
        }
    }

    private cloudProject(fullDocument: any) {
        if (fullDocument.jira) {
            return ""; // This field is not populated for JIRA tickets
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.Cloud_Project__c;
        }
    }

    private isEscalated(fullDocument: any) {
        if (fullDocument.jira) {
            return false; // This field is not populated for JIRA tickets
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.CSM_Escalated__c || fullDocument.sfdc.Customer_Escalated__c;
        }
    }

    private components(fullDocument: any) {
        if (fullDocument.jira) {
            let comps = [];
            let jiraComps = fullDocument.jira.fields.components;
            if (jiraComps) {
                for (let i = 0; i < jiraComps.length; i++) {
                    comps.push(jiraComps[i].name);
                }
            }
            return comps;
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.Components__c;
        }
    }

    private triageComponents(fullDocument: any) {
        if (fullDocument.jira) {
            return []; // This field is not present for JIRA tickets
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.Triage_Components__c;
        }
    }

    private slaTarget(fullDocument: any) {
        if (fullDocument.jira) {
            return ""; // Not populated in JIRA tickets
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.SLA_Target__c;
        }
    }

    private slaTimeRemaining(fullDocument: any) {
        if (fullDocument.jira) {
            return ""; // Not populated in JIRA tickets
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.SLA_Time_Remaining__c;
        }
    }

    private segmentationTier(fullDocument: any) {
        if (fullDocument.jira && (fullDocument.sfdc && fullDocument.sfdc.Account)) {
            return fullDocument.sfdc.Account.Segmentation_Tier__c;
        } else {
            return "";
        }
    }

    private ntses(fullDocument: any) {
        if (fullDocument.caseProject) {
            return fullDocument.caseProject.ntses;
        } else {
            return null;
        }
    }

    private isCloudProjectSFDCIssue(fullDocument: any) {
        if (fullDocument.sfdc) {
            return fullDocument.sfdc.Cloud_Project__c;
        } else {
            return false;
        }
    }

    private status(fullDocument: any) {
        if (fullDocument.jira && fullDocument.jira.fields.status) {
            return fullDocument.jira.fields.status.name;
        } else if (fullDocument.sfdc) {
            return fullDocument.sfdc.Status;
        }
    }



    private intCommLastModByC(fullDocument: any) {
        if (fullDocument.sfdc) {
            return fullDocument.sfdc.Internal_Comments_Last_Modified_By__c;
        } else {
            return "";
        }
    }

    private intCommLastModByR(fullDocument: any) {
        if (fullDocument.sfdc) {
            return fullDocument.sfdc.Internal_Comments_Last_Modified_By__r;
        } else {
            return "";
        }
    }

    private intCommLastModDateC(fullDocument: any) {
        if (fullDocument.sfdc) {
            return fullDocument.sfdc.Internal_Comments_Last_Modified_Date__c;
        } else {
            return "";
        }
    }

    isMongoDBEmail(email: string) {
        // String.prototype.endsWith = function(suffix) {
        //   return this.indexOf(suffix, this.length - suffix.length) !== -1;
        // };

        if (email && (email.endsWith('@mongodb.com') || email.endsWith('@10gen.com'))) {
            return true;
        } else {
            return false;
        }
    }

    isMongoDBUserType(sfdc_user_type: string) {

        return sfdc_user_type === "Standard";

    }

    private __hasMongoDBReporter(fullDocument: any) {

        if (fullDocument.jira) {
            if (fullDocument.jira && fullDocument.jira.fields.reporter.emailAddress) {
                return this.isMongoDBEmail(fullDocument.jira.fields.reporter.emailAddress);
            }
            //return this.isMongoDBEmail(this.reporterEmail);
            return false;
        } else if (fullDocument.sfdc) {
            let reporter_type = fullDocument.sfdc.CreatedByType;
            // we also check SSOId and Email to handle cases without type info
            return (this.isMongoDBUserType(reporter_type) || this.isMongoDBEmail(fullDocument.sfdc.CreatedBySSOId) || this.isMongoDBEmail(fullDocument.sfdc.CreatedByEmail));
        }

    }

    private isProactive(fullDocument: any) {

        let issuetype = "";

        if (fullDocument.jira && fullDocument.jira.fields && fullDocument.jira.fields.issuetype) {
            issuetype = fullDocument.jira.fields.issuetype.name;
        } else if (fullDocument.sfdc) {
            let recordtypemap: any = {
                '012A00000012c0BIAQ': "General Requests",
                '012A0000000r2FpIAI': "Internal Systems",
                '012A00000012c0GIAQ': "Proactive",
                '012000000000000AAA': "Master",
                '0123R0000004YW3QAM': "General Requests Gov Cloud",
                '0123R0000004YW4QAM': "Proactive Gov Cloud",
                '0123d0000004JM3AAM': "General Requests Gov Cloud",
                '0123d0000004JM4AAM': "Proactive Gov Cloud",
                '0123d0000004LuVAAU': "PS File Request Gov Cloud"
            };

            issuetype = recordtypemap[fullDocument.sfdc.RecordTypeId];
        }

        return issuetype.toLowerCase() === "proactive" && this.__hasMongoDBReporter;

    }

    private firstCustomerComment(fullDocument: any) {

        return fullDocument.dash.firstCustomerComment;
    }

    private isDashActiveNow(fullDocument: any) {
        if (fullDocument.doc && fullDocument.doc.dash) {
            return fullDocument.doc.dash.active.now;
        } else {
            return false;
        }
    }

    private isNewProactiveIssue(fullDocument: any) {
        return this.isDashActiveNow(fullDocument) && this.isProactive(fullDocument) && !this.firstCustomerComment(fullDocument);
    }

    private isActive(fullDocument: any) {

        var states = ['Open',
            'Reopened',
            'In Progress',
            'New',
            'Backlog',
            'In Code Review',
            'Investigating',
            'Needs Scheduling',
            'Needs Verification',
            'Ready for Work'
        ];

        var wfcStates = ['Waiting for Customer', 'Waiting for User Input', 'Waiting for Reporter'];

        if (this.isNewProactiveIssue(fullDocument)) {
            return false;
        } else {
            return (states.indexOf(this.getStatus(fullDocument)) > -1 || (this.isDashActiveNow(fullDocument) && wfcStates.indexOf(this.getStatus(fullDocument)) < -1));
        }
    }

    formatSLAIssue(issue: any) {

        //let now = new Date();
        let expireAt: any = new Date(issue.sla.initial.expireAt);
        let startAt: any = new Date(issue.sla.initial.startAt);
        let ts = (expireAt - Date.now()) / 1000;
        let pE = (Date.now() - startAt) / (expireAt - startAt) * 100;

        let created = this.created(issue);
        let createdDate: any = new Date(created);
        let timediff = Math.abs(Date.now() - createdDate) / 1000;
        let days = Math.floor(timediff / (3600 * 24));
        let hours = Math.floor((timediff - (days * 3600 * 24)) / (3600));
        let minutes = Math.floor((timediff - (days * 3600 * 24) - (hours * 3600)) / (60));
        let sf_case_id, jira_case_id;
        if (issue.jira) {
            jira_case_id = issue.jira.key;
        }
        if (issue.sfdc) {
            sf_case_id = issue.sfdc.Id;
        }

        return {
            id: this.key(issue),
            priority: this.priority(issue),
            assignee: this.assigneeDisplayName(issue),
            initialTotalSeconds: ts,
            days: days,
            hours: hours,
            minutes: minutes,
            percentExpired: pE,
            desc: this.reporterSummaryDescription(issue),
            company: this.company(issue),
            sf_project_id: this.sf_project_id(issue),
            isSLA: this.isSLA(issue),
            isFTS: this.isFTS(issue),
            product: this.product(issue),
            cloudProject: this.cloudProject(issue),
            escalated: this.isEscalated(issue),
            owner: null,
            comps: this.components(issue),
            triageComps: this.triageComponents(issue),
            slaTarget: this.slaTarget(issue),
            slaTimeRemaining: this.slaTimeRemaining(issue),
            slaExpiresAt: expireAt,
            earliestLastCustComments: issue.dash.earliestOfLastCustomerComments,
            lastPublicCommentUser: issue.dash.lastPublicCommentAuthor,
            segmentation_tier: this.segmentationTier(issue),
            ntses: this.ntses(issue),
            status: this.status(issue),
            sfdcCaseId: sf_case_id,
            jiraCaseId: jira_case_id
        };

    }

    formatFTSIssue(issue: any) {
        let lastComment = issue.dash.earliestOfLastCustomerComments; // Using the dash subdoc
        let lastUpdate;

        if (lastComment && lastComment.hasOwnProperty("created")) {
            lastUpdate = lastComment.created;
        } else {
            lastUpdate = issue.updated;
        }

        //let now = new Date();
        let lastUpdateDate: any = new Date(lastUpdate);
        let timediff = Math.abs(Date.now() - lastUpdateDate) / 1000;

        let days = Math.floor(timediff / (3600 * 24));
        let hours = Math.floor((timediff - (days * 3600 * 24)) / (3600));
        let minutes = Math.floor((timediff - (days * 3600 * 24) - (hours * 3600)) / (60));
        let followUpExpireAt = null;
        let followUpTotalSeconds = null;

        if (issue.sla && issue.sla.followUp && issue.sla.followUp.expireAt) {
            followUpExpireAt = issue.sla.followUp.expireAt;
        } else if (issue.sla && issue.sla.expireAt) {
            followUpExpireAt = issue.sla.expireAt;
        }

        if (followUpExpireAt) {
            followUpTotalSeconds = (followUpExpireAt - Date.now()) / 1000;
        }

        let sf_case_id, jira_case_id;
        if (issue.jira) {
            jira_case_id = issue.jira.key;
        }
        if (issue.sfdc) {
            sf_case_id = issue.sfdc.Id;
        }

        return {
            id: this.key(issue),
            priority: this.priority(issue),
            assignee: this.assigneeDisplayName(issue),
            followUpTotalSeconds: followUpTotalSeconds,
            days: days,
            hours: hours,
            minutes: minutes,
            desc: this.reporterSummaryDescription(issue),
            company: this.company(issue),
            sf_project_id: this.sf_project_id(issue),
            isSLA: this.isSLA(issue),
            isFTS: this.isFTS(issue),
            product: this.product(issue),
            cloudProject: this.cloudProject(issue),
            escalated: this.isEscalated(issue),
            owner: null,
            comps: this.components(issue),
            triageComps: this.triageComponents(issue),
            slaTarget: this.slaTarget(issue),
            slaTimeRemaining: this.slaTimeRemaining(issue),
            slaFollowUp: issue.sla.followUp,
            slaInitial: issue.sla.initial,
            followUpExpireAt: followUpExpireAt,
            earliestLastCustComments: issue.dash.earliestOfLastCustomerComments, //Using the dash subdoc
            earliestOfLastXGenPublicComments: issue.dash.earliestOfLastXGenPublicComments, // Using the dash subdoc
            lastPublicCommentUser: issue.dash.lastPublicCommentAuthor, //Using the dash subdoc
            segmentation_tier: this.segmentationTier(issue),
            ntses: this.ntses(issue),
            status: this.status(issue),
            sfdcCaseId: sf_case_id,
            jiraCaseId: jira_case_id
        };
    }

    formatIssue(issue: any) {
        let initialTotalSeconds = null;
        let followUpTotalSeconds = null;
        let followUpExpireAt: any = null;
        let waitingTimeSource = "";
        //let startAt = null;
        let expireAt: any = null;
        //let now = new Date();

        if (issue.sla && issue.sla.followUp && issue.sla.followUp.expireAt) {
            followUpExpireAt = new Date(issue.sla.followUp.expireAt);
        }

        if (followUpExpireAt) {
            followUpTotalSeconds = (followUpExpireAt - Date.now()) / 1000;
        }

        // if (issue.sla && issue.sla.initial && issue.sla.initial.startAt) {
        //     startAt = new Date(issue.sla.initial.startAt);
        // }

        if (issue.sla && issue.sla.initial && issue.sla.initial.expireAt) {
            expireAt = new Date(issue.sla.initial.expireAt);
        }
        if (expireAt) {
            initialTotalSeconds = (expireAt - Date.now()) / 1000;
        }

        let lastComment = null;
        let lastUpdateDate: any = null;

        if (this.status(issue) === "In Progress") {
            lastComment = issue.dash.earliestOfLastCustomerComments; // using dash subdoc
            waitingTimeSource = "EarliestOfLastCustomerComments";
        } else {
            lastComment = issue.dash.earliestOfLastXGenPublicComments; // using dash subdoc
            waitingTimeSource = "EarliestOfLastXGenPublicComments";
        }

        if (lastComment && lastComment.created) {
            lastUpdateDate = new Date(lastComment.created);
        } else {
            lastUpdateDate = new Date(issue.updated);
            waitingTimeSource = "CaseUpdatedTime";
        }

        let ts = Math.abs(Date.now() - lastUpdateDate) / 1000;

        let days = Math.floor(ts / (3600 * 24));
        let hours = Math.floor((ts - (days * 3600 * 24)) / (3600));
        let minutes = Math.floor((ts - (days * 3600 * 24) - (hours * 3600)) / (60));

        let sf_case_id, jira_case_id;
        if (issue.jira) {
            jira_case_id = issue.jira.key;
        }
        if (issue.sfdc) {
            sf_case_id = issue.sfdc.Id;
        }

        return {
            id: this.key(issue),
            priority: this.priority(issue),
            assignee: this.assigneeDisplayName(issue),
            initialTotalSeconds: initialTotalSeconds,
            followUpTotalSeconds: followUpTotalSeconds,
            days: days,
            hours: hours,
            minutes: minutes,
            waitingTimeSource: waitingTimeSource,
            slaExpiresAt: expireAt,
            followUpExpireAt: followUpExpireAt,
            desc: this.reporterSummaryDescription(issue),
            isSLA: this.isSLA(issue) ? 1 : 0,
            company: this.company(issue),
            sf_project_id: this.sf_project_id(issue),
            isFTS: this.isFTS(issue) ? 1 : 0,
            owner: null,
            product: this.product(issue),
            cloudProject: this.cloudProject(issue),
            escalated: this.isEscalated(issue),
            comps: this.components(issue),
            triageComps: this.triageComponents(issue),
            intCommLastModByC: this.intCommLastModByC(issue),
            intCommLastModByR: this.intCommLastModByR(issue),
            intCommLastModDateC: this.intCommLastModDateC(issue),
            status: this.status(issue),
            earliestLastCustComments: issue.dash.earliestOfLastCustomerComments, //Using the dash subdoc
            earliestOfLastXGenPublicComments: issue.dash.earliestOfLastXGenPublicComments, // Using the dash subdoc
            lastPublicCommentUser: issue.dash.lastPublicCommentAuthor, //Using the dash subdoc
            segmentation_tier: this.segmentationTier(issue),
            ntses: this.ntses(issue),
            sfdcCaseId: sf_case_id,
            jiraCaseId: jira_case_id
        };

    }

    formatREVIssue(review: any, issue: any) {


        let sf_case_id, jira_case_id;

        if (issue.jira) {
            jira_case_id = issue.jira.key;
        }
        if (issue.sfdc) {
            sf_case_id = issue.sfdc.Id;
        }

        let requestedDate: any;
        let revWaitingTime: any;
        let days: number;
        let hours: number;
        let minutes: number;

        if (review?.requested_at) {
            requestedDate = new Date(review.requested_at);
            revWaitingTime = Math.abs(Date.now() - requestedDate) / 1000;
            days = Math.floor(revWaitingTime / (3600 * 24));
            hours = Math.floor((revWaitingTime - (days * 3600 * 24)) / (3600));
            minutes = Math.floor((revWaitingTime - (days * 3600 * 24) - (hours * 3600)) / (60));
        } else {
            requestedDate = null;
            revWaitingTime = null;
            days = 0;
            hours = 0;
            minutes = 0;
        }

        let SLAtime: any = null;
        let isSLA: any = this.isSLA(issue);
        if (isSLA) {
            let expireAt: any = new Date(issue.sla.initial.expireAt);
            SLAtime = (expireAt - Date.now()) / 1000;
        }

        return {
            id: this.key(review),
            days: days,
            hours: hours,
            minutes: minutes,
            requestedby: review.requested_by ?? '',
            reviewers: review.reviewers ?? [],
            lgtms: review.lgtms ?? [],
            priority: this.priority(issue), //?
            lookers: review.lookers ?? [],
            marked_by: null,
            isSLA: isSLA,
            comment_id: review.comment_id,
            comment_name: review.comment_name,
            components: this.components(issue),
            triageComponents: this.triageComponents(issue),
            product: this.product(issue),       // Not present in JIRA tickets
            cloudProject: this.cloudProject(issue),                            // Not present in JIRA tickets
            escalated: this.isEscalated(issue), // Not present in JIRA tickets
            description: this.reporterSummaryDescription(issue),
            slaTarget: this.slaTarget(issue),
            slaTimeRemaining: this.slaTimeRemaining(issue),
            segmentation_tier: this.segmentationTier(issue),
            ntses: this.ntses(issue),
            SLAtime: SLAtime,
            sfdcCaseId: sf_case_id,
            sfdcCommentId: review?.sfdc?.comment_id,
            jiraCaseId: jira_case_id
        };

        //     } else {
        //       console.log('No document matches the provided issue key.');
        //     }
        //   })
        //   .catch((err:any) => console.error(`Failed to find issue document: ${err}`));

        //.sort({_id : -1}).limit(1); 

    }

}
