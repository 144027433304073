import React from "react";
import "./TCHelper.css";
import Toggle from '@leafygreen-ui/toggle';
import Carousel from "../Carousel/Carousel";
import TCHelperCard from '../TCHelperCard/TCHelperCard'
import CheckboxFilter from "../CheckboxFilter/CheckboxFilter";
import moment from 'moment-timezone';
import TCHelperRow from "../TCHelperFull/TCHelperRow";
import { debounce } from "throttle-debounce";
import { FaBicycle } from "react-icons/fa";

type TCHelperProps = {
    stitchClient: any,
    isTC: Boolean,
    navType: string,
    hub: string, //hub abbreviation
    eztc_inspector_url: string
}

type TCHelperState = {
    toggleState: Boolean,
    tcData: {}[],
    filteredTcData: {}[],
    tcDataLoaded: Boolean,
    distinctRegion: Array<any>,
    distinctTeam: Array<any>,
    timezones: Array<string>,
    types: Array<string>,
    filteredSelectedTimezones: Array<string>,
    filteredSelectedTeams: Array<string>,
    filteredSelectedTypes: Array<string>,
    firstTCHLoad: Boolean,
    searchString: string
}

const timezones = [

    {moment: "Australia/Sydney", order: 1,
    abbreviation: "APAC-SYD"},
    {moment: "Asia/Singapore", order: 2,
    abbreviation: "APAC-SIN"},
    {moment: "Asia/Calcutta", order: 3,
    abbreviation: "APAC-DEL"},
    {moment: "Asia/Tel_Aviv", order: 4,
    abbreviation: "EMEA-TLV"},
    {moment: "Europe/Paris", order: 5,
    abbreviation: "EMEA-CEN"},
    {moment: "Europe/Dublin", order: 6,
    abbreviation: "EMEA-DUB"},
    {moment: "America/Argentina/Buenos_Aires", order: 7,
    abbreviation: "AMER-SA"},
    {moment: "America/New_York", order: 8,
    abbreviation: "AMER-East"},
    {moment: "America/Chicago", order: 9,
    abbreviation: "AMER-Central"},
    {moment: "America/Los_Angeles", order: 10,
    abbreviation: "AMER-West"},
]

const LOCAL_STORAGE = 'preferences.tchelper';

class TCHelper extends React.Component<TCHelperProps, TCHelperState> {
    constructor(props: any) { 
        super(props);  
       
        let filteredSelectedTimezones: Array<string> = []; 
        let filteredSelectedTeams = null;
        let filteredSelectedTypes = null;

        let storage;
        if (localStorage) {
          const item = localStorage.getItem(LOCAL_STORAGE);
          if (item) {
            storage = JSON.parse(item); 
            // filteredSelectedTimezones = storage.filteredSelectedTimezones; 
            filteredSelectedTeams = storage.filteredSelectedTeams; 
            filteredSelectedTypes = storage.filteredSelectedTypes;
          } 
        } 
 
        this.state = {
            toggleState: true,
            tcData: [],
            tcDataLoaded: false,
            distinctRegion: [],
            distinctTeam: [],
            timezones: timezones.map((tz)=> {return tz.abbreviation}),
            types: ["Active","Inactive","OOO"],
            filteredSelectedTimezones, 
            filteredSelectedTeams, 
            filteredSelectedTypes, 
            filteredTcData: [],
            firstTCHLoad: true,
            searchString: '',
        }
    }

    toggleClick = (event: any) => {
        let { toggleState } = this.state;
        let newToggleState = (toggleState === true ? false : true);
        if(newToggleState === true) {
            // then we need to kick off the loader
            this.getTCHelperData(this.props.stitchClient);
        }

        this.setState({
            toggleState: newToggleState
        })
    }

    componentDidMount() {
        this.getTCHelperData(this.props.stitchClient);
        setInterval(this.doDataCalls,30*1000);
    }

    doDataCalls = () => {
        const {toggleState} = this.state;
        const {navType} = this.props;
        //if tc helper is open, then lets do these calls, if not, dont call anywhere.
        if(toggleState || navType === "static") {
            this.getTCHelperData(this.props.stitchClient);
        }
    }

    filterResponse(list: any) {
        let accepted: any[] = [];

        let isOOO, isPT, isUnramped, isBusy, isInactive;
        list.forEach((item: any) => {
            // Filter out TSE if 1) TSE is OOO or PT in event calendar; 2) Unramped; 3) busy
            isOOO = (item.calendar && item.calendar.ooo) ? item.calendar.ooo : false;
            isPT = (item.calendar && item.calendar.PT != null) ? item.calendar.PT : false;
            isUnramped = (item.Case_Comment_Privileges__c && item.Case_Comment_Privileges__c === 'Unramped');

            // Check if Slack status mentions keyword 'Busy'
            isBusy = (item.slack_profile &&
                item.slack_profile.profile  &&
                item.slack_profile.profile.status_text) ? 
                item.slack_profile.profile.status_text.toLowerCase().includes('busy') : false;

            isInactive = (item.slack_profile &&
                item.slack_profile.presence && item.slack_profile.presence === 'away');

            if (isOOO){
                item.slack_profile.profile.status_text = item.slack_profile.profile.status_text + " " + item.calendar.summary_split;
            }
            if (isPT) {
                item.isPT = true;
                item.slack_profile.profile.status_text = "In Protected Time " + item.slack_profile.profile.status_text;
            }

            if(isOOO) {
                Object.assign(item, { type: 'OOO'} );
                accepted.push(item);
            } else if (isPT || isUnramped || isBusy || isInactive){
                Object.assign(item, { type: 'Inactive' });
                accepted.push(item);
            } else {
                Object.assign(item, { type: 'Active'}) ;
                accepted.push(item);
            }

        });
        return accepted;
    }

   
    getCurrentTimeZone = () => {
        //document.getElementsByClassName("topcorner")[0].innerHTML = 'Current UTC hour: ' + moment.utc().hours();

        const d = new Date();
        const dow = d.getDay();

        let regions_now: string[] = [];

        const start_hour = 9;
        const end_hour = (dow >= 1 && dow <= 4 ? 17 : 16); // Monday-Thursday one queue until 5pm, otherwise 4pm
        timezones.forEach((tz) => {
            let dt_hours = moment.tz(tz.moment).hours();
            if(dt_hours >= start_hour && dt_hours < end_hour) {
                regions_now.push(tz.abbreviation);
            }
        })
        return regions_now;
    }

    getTCHelperData = (client: any) => {
            const {filteredSelectedTimezones, filteredSelectedTeams, filteredSelectedTypes} = this.state;
            client && client.callFunction("getTCHelperData").then((response: any) => {
            console.log(`-----Get TCHelperData view Data: `, response);
            if (response.length) {
                const tcList = this.filterResponse(response)
                const teams = Array.from(new Set(tcList.map((item: any) => item.TS_Team__c)));
                const timezones = this.getCurrentTimeZone();
                const isTC = this.props.isTC;
                const { toggleState, firstTCHLoad, searchString } = this.state; 
               
                const filteredTeams =  filteredSelectedTeams ?? teams;  
                // const filteredTimezones = filteredSelectedTimezones  ?? timezones;  
                const filteredTimezones = (filteredSelectedTimezones && filteredSelectedTimezones.length > 0) ? filteredSelectedTimezones : timezones;  

                const filteredTypes = filteredSelectedTypes  ?? ["Active", "Inactive"];  
                tcList.forEach((item : any) => {
                    this.addQuickSearch(item);
                });

                this.setState({
                    tcData: tcList.sort(function(a,b) { 
                        if (a.score < b.score) {
                            return -1;
                        }
                        if (b.score < a.score) {
                            return 1;
                        }
                        return 0;  
                    }),
                    // distinctTZ: Array.from(new Set(tcList.map((item: any) => item.TZ))),
                    distinctTeam: teams,
                    filteredSelectedTeams: filteredTeams,
                    filteredSelectedTimezones : filteredTimezones,
                    filteredSelectedTypes: filteredTypes,
                    filteredTcData: this.recalcFilter(searchString, tcList, filteredTimezones, filteredTeams, filteredTypes),
                    tcDataLoaded: true
                });

                // hide TC Helper if user is not TC on Page Load for the 1st time
                this.checkDefaultView(isTC, toggleState, firstTCHLoad);
            } else {
                console.log('Error: No Data for TcHelper');
            }
        }).catch((err: any) => {
            console.error(err);
        })
    }
    addQuickSearch(item: any) {
        let quickSearch = '';
        quickSearch += item.FirstName.toLowerCase() + ' ' + item.LastName.toLowerCase();
        quickSearch += ';';

        if(item.Skills && item.Skills.length) {
            quickSearch += item.Skills.map((x: any) => x.Skill__c.toLowerCase()).join(';');
        }
        //if you want to add more items to search use
        //quicksearch += string
        // and quicksearch += ";";
        return Object.assign(item, {quickSearch: quickSearch});
    }
    // recalcFilter(data: any[], tmzs: Array<string>, teams: Array<string>): {}[] {
    //     if(tmzs && tmzs.length > 0) {
    //         data = data.filter((item) => { return tmzs.includes(item.TZ); })
    //     }
    //     if(teams && teams.length > 0) {
    //         data = data.filter((item) => { return teams.includes(item.Team); })
    //     }
    //     return data;
    // }

    recalcFilter(searchString: string, data: any[], tmzs: Array<string>, teams: Array<string>, types: Array<string>): {}[] {
        //Per TSTOOLS-2220, if all checkboxes are unchecked, filter anyways
        //if(types && types.length > 0) {
        if(types ) {
            data = data.filter((item) => { return types.includes(item.type)})
        } 
        if(tmzs) {
            data = data.filter((item) => { return tmzs.includes(item.TS_Region__c); })
        }
        if(teams) {
            data = data.filter((item) => { return teams.includes(item.TS_Team__c); })
        }
        // if no text search, exit.
        if (searchString && searchString.length === 0) {
            return data;
        } else {
            data = data.filter((item: any) => { return item.quickSearch.indexOf(searchString) >= 0; });
        }

        return data;
    }

    updateSelectedTypes = (ev: any, data: any) => {
        this.setState({
            filteredSelectedTypes: data,
            filteredTcData: this.recalcFilter(this.state.searchString, this.state.tcData, this.state.filteredSelectedTimezones, this.state.filteredSelectedTeams, data)
        });

        this.savePreferences('filteredSelectedTypes', data);
    }
    updateSelectedTimezones = (ev: any, data: any) => {
        this.setState({
            filteredSelectedTimezones: data,
            filteredTcData: this.recalcFilter(this.state.searchString, this.state.tcData, data, this.state.filteredSelectedTeams, this.state.filteredSelectedTypes)
        });

       // this.savePreferences('filteredSelectedTimezones', data);
    }
    updateSelectedTeams = (ev: any, data: any) => {
        this.setState({
            filteredSelectedTeams: data,
            filteredTcData: this.recalcFilter(this.state.searchString, this.state.tcData, this.state.filteredSelectedTimezones, data, this.state.filteredSelectedTypes)
        });

        this.savePreferences('filteredSelectedTeams', data);
    }

    checkDefaultView = (isTC: Boolean, toggleState: Boolean, firstTCHLoad: Boolean) => {
        if (toggleState && !isTC && firstTCHLoad) {
            this.setState({
                toggleState: false,
                firstTCHLoad: false
            })
        }
    }

    updateTextSearchDebounced = debounce(250, (val: string) => {
        if (this.state.searchString !== val) {
            this.setState({
                searchString: val,
                filteredTcData: this.recalcFilter(val, this.state.tcData, this.state.filteredSelectedTimezones, this.state.filteredSelectedTeams, this.state.filteredSelectedTypes)
            });
        }
    }); 

    savePreferences = (prop: any, value: any) => {

        const { /* filteredSelectedTimezones, */ filteredSelectedTeams, filteredSelectedTypes } = this.state;

        localStorage.setItem(LOCAL_STORAGE, JSON.stringify({
            // filteredSelectedTimezones,
            filteredSelectedTeams,
            filteredSelectedTypes,
            [prop]: value
        }));
    }

    render() {
        // eslint-disable-next-line
        const { toggleState, tcData, filteredTcData, tcDataLoaded, distinctTeam, types, 
            timezones, filteredSelectedTimezones, filteredSelectedTeams, filteredSelectedTypes } = this.state;
        const {navType, hub} = this.props;

        const showFullView = navType === "static" && hub === "TCHELPER";
            
        return (
            <div className={"tc-helper " + (toggleState === true || showFullView ? 'enabled' : 'disabled') }>
                <div className="tc-helper-header">
                    <Toggle
                        className={'tc-helper-toggle ' + (showFullView ? 'hidden' : 'visible')}
                        onChange={(event) => {this.toggleClick(event)}}
                        checked={(toggleState && toggleState === true) ? true : false}
                        size="small"
                        aria-labelledby="tcHelper-label"
                    />
                    <div className="tc-helper-title">TC Helper</div>
                </div>
                <div className="tc-helper-body">
                    <div className={"table-filters " + (toggleState === true || showFullView ? 'visible' : 'hidden')}>
                        <div className="table-wrapper-search">
                            <div className="">
                                <CheckboxFilter options={types} selected={filteredSelectedTypes} onChange={this.updateSelectedTypes} idName="CheckboxHelper"></CheckboxFilter>
                            </div>
                        </div>
                        <div className="table-wrapper-search">
                            <input type="text" className="searchbox" placeholder={`Search name or skill`}
                                onChange={(ev) => { this.updateTextSearchDebounced(ev.target.value.toLowerCase()); }} />
                        </div>
                    </div>
                    {/* Region Group  TODO DISPLAY?HIDE THESES */} 
                    <div className={"tc-helper-filters " + (toggleState === true || showFullView ? 'visible' : 'hidden')} style={{display: (tcData.length > 0) ? '' : 'none'}}>
                        <div className="timezone">
                            <CheckboxFilter options={timezones} selected={filteredSelectedTimezones} onChange={this.updateSelectedTimezones} idName="CheckBoxTimezone"></CheckboxFilter>
                        </div>
                        <div className="team">
                            <CheckboxFilter options={distinctTeam} selected={filteredSelectedTeams} onChange={this.updateSelectedTeams} idName="CheckBoxTeam"></CheckboxFilter>
                        </div>    
                    </div>
                    

                    <Carousel slidesToScroll={6} slidesToShow={6} infinite={false} visible={(toggleState && !showFullView && tcData.length > 0)} >
                        {filteredTcData && filteredTcData.map((item, i: number) => (
                            <TCHelperCard key={"tc-helper-card-" + i} item={item} eztc_inspector_url={this.props.eztc_inspector_url}/> 
                        ))}
                    </Carousel>

{/* IF FULL VIEW */}
                    <div className={"table-wrapper "  + (showFullView ? 'visible' : 'hidden')}>
                            <table className="table">
                                <thead>
                                    <tr className="table-header">
                                        <th className="th_column_4"></th>
                                        <th className="th_column_4"></th>
                                        <th className="th_column_10">Name</th>
                                        <th className="th_column_4">Load</th>
                                        <th className="th_column_10">Location</th>
                                        <th>Type</th>
                                        <th className="th_column_10">Skills</th>
                                        <th>FTS</th>
                                        <th className="align-left">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredTcData && filteredTcData.map((item: any, i: number) => (
                                        <TCHelperRow key={"tcItem-" + i} item={item} eztc_inspector_url={this.props.eztc_inspector_url} searchString={this.state.searchString}></TCHelperRow>
                                    ))}   
              
                                </tbody>
                            </table>
                            <div className="table-empty-container well text-center">
                                <div className="empty-table-message">
                                    <FaBicycle /><div>Neat! No items to show.</div>
                                </div>
                            </div>     
                        </div>
                </div>
                
            </div>
        )
    }
}

export default TCHelper;

