import React from "react";
import { v4 as uuid } from 'uuid';
import "./CheckboxFilter.css";
import Checkbox from '@leafygreen-ui/checkbox';

type CheckboxFilterProps = {
    options: Array<string>,
    selected?: Array<string>,
    checkboxClass?: string;
    checkboxGroupClass?: string;
    onChange?: Function;
    idName?:string;
}

type CheckboxFilterState = {
    // selectedValues: any,
    uniqueSalt: string
}

class CheckboxFilter extends React.Component<CheckboxFilterProps, CheckboxFilterState> {
    static defaultProps = {
        options: [],
        selected: [],
        radioClass: '',
        radioGroupClass: '',
        onChange: (ev: any) => { /** do nothing */ }
    }

    UNSAFE_componentWillMount() {
        this.setState({
            uniqueSalt: uuid()
        });
    }

    onClick = (ev: any, opt: string) => {
        if (this.props.selected && this.props.selected.includes(opt)) {
            // this is already selected, so take it away
            let sv = this.props.selected.filter(e => e !== opt);
            this.props.onChange && this.props.onChange(ev, sv);
        } else {
            //add this
            let sv = this.props.selected;
            sv && sv.push(opt);
            this.props.onChange && this.props.onChange(ev, sv);
        }
    }

    getSafeValue = (options: Array<string>) => {
        if (options && options.length && options.length > 0 && options[0]) {
            return options[0];
        }
        return 0;
    }

    render() {
        const { options, checkboxClass, checkboxGroupClass, selected } = this.props;
        return (
            <div className={"checkbox-filter " + checkboxGroupClass}>
                {options && options.length && options.map((opt: string, idx: number) => (
                    <Checkbox id={'checkbox-' + this.props.idName + idx}  key={this.state.uniqueSalt + idx} className={"checkbox " + checkboxClass}
                        label={opt} checked={selected && selected.includes(opt)}
                        onClick={(ev) => this.onClick(ev, opt)}                              
                    ></Checkbox>
                ))}
            </div>
        )
    }
}

export default CheckboxFilter;