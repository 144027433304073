import React, { useState } from 'react';
import { FC } from "react";
import Badge from '@leafygreen-ui/badge';
import { ToolsNav } from "@ts-tools/tools-nav";
import useDarkMode from 'use-dark-mode';
import { ActorDetails } from '@ts-tools/tools-nav/dist/esm/TopBar/TopBar';
import Icon from '@leafygreen-ui/icon';

export type NavItem = {
  type: string,
  title: string,
  value: string,
  count?: string
}

export type HeaderProps = {
  currentUser: any;
  currentHubAbbr: String;
  navData: NavItem[];
  updateView: Function;
  actorDetails: ActorDetails[] | undefined;
  currentSyncJira: number
  currentSyncSfdc: number
}

export const Header: FC<HeaderProps> = ({ currentUser, navData, updateView, actorDetails, currentSyncJira, currentSyncSfdc, currentHubAbbr }) => {
  const darkMode = useDarkMode(false, {
      classNameDark: 'dark',
      classNameLight: 'light'
  });
  
  const [lastSyncJiraDt, setLastSyncJiraDt] = useState(currentSyncJira);
  const [lastSyncSfdcDt, setLastSyncSfdcDt] = useState(currentSyncSfdc);
  const [jiraDiff, setJiraDiff] = useState(0);
  const [sfdcDiff, setSfdcDiff] = useState(0);

  if (currentSyncJira !== lastSyncJiraDt || currentSyncSfdc !== lastSyncSfdcDt) { 
    const now = new Date();
    const jiraLastSync = new Date(currentSyncJira);
    const sfdcLastSync = new Date(currentSyncSfdc);

    const jiraDiff = Math.ceil((now.valueOf() - jiraLastSync.valueOf()) / 1000);
    const sfdcDiff = Math.ceil((now.valueOf() - sfdcLastSync.valueOf()) / 1000);
    setJiraDiff(jiraDiff);
    setSfdcDiff(sfdcDiff);
    setLastSyncJiraDt(currentSyncJira);
    setLastSyncSfdcDt(currentSyncSfdc);
  }

  const tabs = navData.map((navItem: any) => {
    const isValidCount = (count: any) => count && count !== "undefined/NaN" && !Number.isNaN(count);

    return {
      default: navItem.value === currentHubAbbr,
      to: navItem.value.toLowerCase(),
      target: "_self",
      rel: "",
      onClick: () => updateView(navItem),
      name: <span>{navItem.title}{isValidCount(navItem.count) ? <Badge style={{ marginLeft: 5 }} variant={darkMode.value ? "darkgray" : "lightgray"}>{navItem.count}</Badge> : null}</span>
    }
  })

  tabs.push({
    default: false,
    to: "//search.corp.mongodb.com",
    target: "_blank",
    rel: "noopener noreferrer",
    onClick: () => void 0,
    name: <><span>Search</span><Icon glyph="OpenNewTab" /></>
  })

  
  let username = 'Test User';
  if (currentUser && currentUser.profile && typeof currentUser.profile.email !== 'undefined') {
      const user = currentUser as any;
      username = user.profile.data.first_name + ' ' + user.profile.data.last_name;
  }
  const session = {
      username,
      name: username,
      email: currentUser?.profile?.email
  }
  return (
    <ToolsNav
      currentHub="Support Hub"
      isHubNav={true}
      title="Support hub"
      showShiftCalendar={actorDetails !== undefined && Array.isArray(actorDetails) && actorDetails.length > 0}
      showTimeDisplay={true}
      actorDetails={actorDetails}
      showUserMenu={true}
      session={session}
      darkMode={darkMode}
      tabs={tabs}
      last_jira_sync={(jiraDiff >= 60 && jiraDiff < 99999999) ? jiraDiff : undefined}
      last_sfdc_sync={(sfdcDiff >= 60 && sfdcDiff < 99999999) ? sfdcDiff : undefined}
    />
  )
};
