import * as React from "react";
import BaseTable, {Column, OnColumnSort} from 'react-base-table';
import { debounce } from "throttle-debounce";

type Props2 = {
    config: any,
    data: any,
    components: any,
    sortState:any,
    onColumnSort: OnColumnSort
};
type State2 = {
    width: number,
    height: number
}


export default class BaseTableResizer extends React.Component < Props2, State2>  {
    constructor(props: Props2) {
        super(props)
        this.state = { width: 1000, height: this.getHeight() };
    }
    updateDimensions = debounce(250,() => {
        this.setState({ width: window.innerWidth, height: this.getHeight() });
    });
    getHeight() {
        const header = document.querySelector('.app-header');
        const nav =  document.querySelector('.hub-nav');
        const tc = document.querySelector('.tc-helper');
        const filters = document.querySelector('.table-filters');
        return window.innerHeight - 80 //for title and header
            - (header ? header.clientHeight : 0)
            - (nav ? nav.clientHeight : 0)
            - (tc ? tc.clientHeight : 0)
            - (filters ? filters.clientHeight : 0);
    }
    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }
    render() {
        const {data, components, sortState, onColumnSort, config} = this.props;
        const {height} = this.state;
        console.log("Rendering AutodeskResizeTableWrapper");
        return <BaseTable
                className="table scrolling-table"
                data={data} //safe filter
                width={1920}
                rowHeight={75}
                maxHeight={height}
                components={components}
                sortState={sortState}
                onColumnSort={onColumnSort}>
                    {config.columns.map((c: any) => (
                        <Column {...c}/>
                    ))}
            </BaseTable>
    }
}