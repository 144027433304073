import React from "react";
import "./TCHelperRow.css";
import Badge from '@leafygreen-ui/badge';
import LoadScore from "./LoadScore";
//import Emojione from "react-emoji-render";
import { Emoji } from "emoji-mart";
import ToolTip from '@leafygreen-ui/tooltip';


type Props = {
    item: any
    eztc_inspector_url: string,
    searchString: string
}
type State = {

}

const skillsMap: any = {
    "Atlas": {
        color: "#72cc35",
        short: "Atlas"
    },
    "Atlas - Performance": {
        color: "#72cc35",
        short: "Performance"
    },
    "Atlas - Billing & Account": {
        color: "#72cc35",
        short: "B&A"
    },
    "Atlas - Migrations": { 
        color: "#72cc35",
        short: "Migr"
    },
    "Atlas - Onboarding": {
        color: "#72cc35",
        short: "Onb"
    },
    "Atlas - Other": {  //Added this after pull from hub.js
        color: "#72cc35",
        short: "Atlas"
    },
    "Atlas - Security": {
        color: "#72cc35",
        short: "Sec"
    },
    "CM - Automation": {
        color: "#11ccb8",
        short: "Atm"
    },
    "CM - Monitoring": {
        color: "#11ccb8",
        short: "Mon"
    },
    "CM - Backup": {
        color: "#11ccb8",
        short: "CM - Backup"
    },
    "CM - Other": {
        color: "#11ccb8",
        short: "Other"
    },
    "Install/Upgrade": {
        color: "#b40e01",
        short: "I/U"
    },
    "Core": {
        color: "#b40e01",
        short: "Core"
    },
    "Security - SSL": {
        color: "#b40e01",
        short: "SSL"
    },
    "Security - Authentication": {
        color: "#b40e01",
        short: "Security - Authentication"
    },
    "Sharding": {
        color: "#b40e01",
        short: "Sharding"
    },
    "Replication": {
        color: "#b40e01",
        short: "Replication"
    },
    "Performance": {
        color: "#b40e01",
        short: "Perf"
    },
    "Other": {
        color: "#b40e01",
        short: "Other"
    },
    "Administrative": {
        color: "#b40e01",
        short: "Admin"
    },
    "Queries": {
        color: "#b40e01",
        short: "Queries"
    },
    "Indexing": {
        color: "#b40e01",
        short: "Index"
    },
    "Drivers": {
        color: "#b40e01",
        short: "Drivers"
    },
    "Driver-C#": {
        color: "#b40e01",
        short: "C#"
    },
    "Driver-Node": {
        color: "#b40e01",
        short: "Node"
    },
    "Driver-Java": {
        color: "#b40e01",
        short: "Java"
    },
    "Enterprise Build - LDAP": {
        color: "#b40e01",
        short: "LDAP"
    },
    "Enterprise Build - General": {
        color: "#b40e01",
        short: "Gen"
    },
    "A&I": {
        color: "#5394fe",
        short: "A&I"
    },
    "BI Connector": {
        color: "#5394fe",
        short: "Bic"
    },
    "OM - Install or Upgrade": {
        color: "#5394fe",
        short: "I/U"
    },
    "OM - Backup": {
        color: "#5394fe",
        short: "Bckp"
    },
    "OM - Security": {
        color: "#5394fe",
        short: "Sec"
    },
    "OM - Monitoring": {
        color: "#5394fe",
        short: "Mon"
    },
    "OM - Sizing": {
        color: "#5394fe",
        short: "Szng"
    },
    "OM - Automation": {
        color: "#5394fe",
        short: "Atm"
    },
    "OM - Other": {
        color: "#5394fe",
        short: "Other"
    },
    "A&I/Kerberos": {
        color: "#5394fe",
        short: "A&I/Kerberos"
    },
    "Language": {
        color: "#feacb8",
        short: "Fr"
    },
    "Language/French": {
        color: "#feacb8",
        short: "French"
    },
    "Language/Italian": {
        color: "#feacb8",
        short: "Italian"
    },
    "Language/Spanish": {
        color: "#feacb8",
        short: "Spanish"
    }
};

class TCHelperRow extends React.Component<Props, State> { 

    convertSkill = (skill: string) => {
        let conv: any = skillsMap[skill];
        return conv != null ? conv.short : skill.slice(0, 5);
    }

    getName = (item: any) => {
        return (item && item.FirstName && item.LastName) ? 
            item.FirstName + ' ' + item.LastName :
            (item && item.FederationIdentifier) ? item.FederationIdentifier.split('@')[0] : 'No-Data';
    }
    getRegion = (item: any) => {
        return (item && item.TS_Region__c) ? item.TS_Region__c : 'N/A';
    }
    getTeam = (item: any) => {
       return (item && item.TS_Team__c) ? item.TS_Team__c : 'N/A';
    }

    getDotStatusClass = (item: any) => {
        // Determine availability green/grey dot by checking slack_profile.presence has keyword 'active'
        let avail = (item && item.slack_profile && item.slack_profile.presence && item.slack_profile.presence === 'active');
        return 'dot ' + (avail ? 'available' : 'notavailable');
    }

    summarizeActiveTickets = (item: any) => {
        let tickets = [];
        if(item && item.active) {
            for(let prop in item.active) {
                if(item.active[prop].length && item.active[prop].length > 0 ) {
                    tickets.push(prop.toUpperCase() + ': ' + item.active[prop].length);
                }
            }
        }
        
        return tickets.length > 0 ? tickets.join(', ') : '0';
    }

    summarizeWaitingTickets = (item: any) => {
        let tickets = [];
        if(item && item.waiting) {
            for(let prop in item.waiting) {
                if(item.waiting[prop].length && item.waiting[prop].length > 0 ) {
                    tickets.push(prop.toUpperCase() + ': ' + item.waiting[prop].length);
                }
            }
        }
        
        return tickets.length > 0 ? tickets.join(', ') : '0';
    }

    summarizeSkillLevels = (skills: any, level: string, searchText: string) => {
        if(skills && skills.length) {
            let set: any[] = skills.filter((x: any) => x.Skill_Level__c === level);
            if(searchText && set && set.length > 0) {
                return set.map<React.ReactNode>(x => {
                    if(x.Skill__c.toLowerCase().includes(searchText)) {
                        return <b style={{color: "#DB3030"}} key={x.Skill__c}>{x.Skill__c}</b> 
                    } else {
                        return <span key={x.Skill__c}>{x.Skill__c}</span> 
                        }
                })
                .reduce((prev, curr) => { return prev === null ? curr : [prev, ' | ', curr] });
            } else {
                return set.length > 0 ? set.map(x => x.Skill__c).join(' | ') : 'None';
            }
        } else {
            return 'None';
        }
    }

    getFTS = (item: any) => {
        let hasFTS = false;
        if(item && item.active) {
            for(let prop in item.active) {
                if(item.active[prop].length && item.active[prop].length > 0 ) {
                    if (item.active[prop].filter((i: any) => i.fts === true).length > 0) {
                        //console.log('hey');
                        hasFTS = true;
                    }
                }                
            }
        }
        if(item && item.waiting) {
            for(let prop in item.waiting) {
                if(item.waiting[prop].length && item.waiting[prop].length > 0 ) {
                    if(item.waiting[prop].filter((i: any) => i.fts === true).length > 0) {
                        // console.log('hey');
                        hasFTS = true;
                    }
                }                
            }
        } 
        return hasFTS;
    }

    getSlackEmoji = (item: any) => {
        return (item.slack_profile && item.slack_profile.profile && item.slack_profile.profile.status_emoji) ? item.slack_profile.profile.status_emoji : '';
    }

    getSlackStatus = (item: any) => {
        let slackStatus: any = item.slack_profile && item.slack_profile.profile && item.slack_profile.profile.status_text ? item.slack_profile.profile.status_text : '';
        let isCurrentTC: any = item.slack_profile && item.slack_profile.profile && item.slack_profile.profile.is_tc === true ? 'CURRENT TC - ' : '';
        return isCurrentTC + slackStatus;
    }

    getSlackAvatar = (item: any) => {
        if (item.slack_profile && item.slack_profile.profile  && item.slack_profile.profile.image_32 && item.slack_profile.id && item.slack_profile.team_id) {
            return <a href={"slack://user?team=" + item.slack_profile.team_id + "&id=" + item.slack_profile.id }><img alt="slack link" src={item.slack_profile.profile.image_32}/></a>
        } else if (item.slack_profile && item.slack_profile.profile  && item.slack_profile.profile.image_32) {
            return <img alt="slack link" src={item.slack_profile.profile.image_32}/>
        } else if (item.slack_profile && item.slack_profile.id && item.slack_profile.team_id) {
            return <a href={"slack://user?team=" + item.slack_profile.team_id + "&id=" + item.slack_profile.id }>X</a>
        } 
        return '';
    }

    showOnlyMatchingSkill = (skill: string, search: string) => {
        let conv: any
        if(skill.includes(search)) {
            conv = skillsMap[skill];
            return conv != null ? conv.short : skill.slice(0, 5);
        }
    };

    render() { 
        // eslint-disable-next-line
        const { item, searchString } = this.props;
        return (
            <tr className="table-row">
                <td><span className={this.getDotStatusClass(item)} title={(item.slack_profile && item.slack_profile.presence) ? 'presence: ' + item.slack_profile.presence : ''}></span></td>
                <td>{this.getSlackAvatar(item)}</td>
                <td>
                    {item && item.ntseProjects && (item.ntseProjects.length > 0) &&<ToolTip align="bottom" justify="end" trigger={<span>{this.getName(item)}</span>} triggerEvent="hover">
                        <div className="tc-tooltip-popover"> 
                            <b>NTSE Projects</b><br/>
                            {item.ntseProjects && item.ntseProjects.map((proj: any) => (
                                <span key={proj}>{proj.name}<br/></span>
                            ))}<br/>
                        </div>
                    </ToolTip>}
                    {item && (!item.ntseProjects || (item.ntseProjects && item.ntseProjects.length <= 0 )) && <span>{this.getName(item)}</span> }
                </td>
                <td>{item && <LoadScore score={item.score} _id={item._id} isCarousell={false} eztc_inspector_url={this.props.eztc_inspector_url}/>}</td>
                
                <td className={(item.isPT ? "ispt" : "")}>{this.getRegion(item)}</td>
                <td className={(item.isPT ? "ispt" : "")}>{this.getTeam(item)}</td>
                <td>{item && item.Skills && item.Skills.length > 0 && item.Skills.slice(0,3).map((skill: any, index: Number) => (
                        <div key={item._id + '-' + index} className={(index >= 2 ? "badge last-line" : "badge")}>
                            <Badge autoCapitalize="true" title={skill.Skill__c} className="tc-badge badge-lightgray">
                                {this.convertSkill(skill.Skill__c)}
                            </Badge>
                        </div>
                    ))}
                    {item && item.Skills && (item.Skills.length > 3) &&<ToolTip align="bottom" justify="end" trigger={<Badge className="last-line tiny-badge tc-badge badge-lightgray">&#183;&#183;&#183;</Badge>} triggerEvent="hover">
                        <div className="tc-tooltip-popover"> 
                            <b>Level 3</b><br/>
                            {item && <span>{this.summarizeSkillLevels(item.Skills, '3', searchString)}</span>}<br/>
                            <b>Level 2</b><br/>
                            {item && <span>{this.summarizeSkillLevels(item.Skills, '2', searchString)}</span>}<br/>
                            <b>Level 1</b><br/>
                            {item && this.summarizeSkillLevels(item.Skills, '1', searchString)}<br/>
                        </div>
                    </ToolTip>}
                </td>
                <td>{this.getFTS(item) && <Emoji emoji=":sun_with_face:" size={16}/>}</td>
                <td  className={"align-left " + (item.isPT ? "ispt" : "")}><Emoji emoji={this.getSlackEmoji(item)} size={16}/> {this.getSlackStatus(item)}</td>
            </tr>  
        )
    }

}

export default TCHelperRow;