import React from "react";
import "./Feedback.css";
import { FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa';
import Modal from '@leafygreen-ui/modal';
import Button from '@leafygreen-ui/button';
import { HubConfigDataType } from "../_Extensions/HubDataAddAutoTCExtension";
// import { Stitch, StitchUser, StitchAppClient } from 'mongodb-stitch-browser-sdk';

type FeedbackProps = {
    stitchClient: any,
    autoTCData: HubConfigDataType | undefined,
    item: any
}

type FeedbackState = {
    voted: boolean,
    isFeedbackOpen: boolean,
    isOpen: boolean,
    feedbackObj: {},
    btn1: boolean,
    btn2: boolean,
    btn3: boolean,
    btn4: boolean,
    btn5: boolean,
    votedResult: string
}

interface feedbackObj {
    caseNumber: number;
    assignee: string;
    voter: string;
    reasons: string[];
    feedback: string;
    caseAnalysisId: string;
    updated: any;
}

class Feedback extends React.Component<FeedbackProps, FeedbackState> {
    constructor(props: FeedbackProps) {
        super(props);

        this.state = {
            voted: false,
            isFeedbackOpen: false,
            isOpen: true,
            feedbackObj: {},
            btn1: false,
            btn2: false,
            btn3: false,
            btn4: false,
            btn5: false,
            votedResult: ''
        }
    }

    // Update feedback option in State for thumbsdown
    updateFeedback = (btn: string) => {
        if (btn === "btn1") {
            this.setState(({ btn1 }) => ({
                btn1: (btn1 === true) ? false : true
            }))
        }
        if (btn === "btn2") {
            this.setState(({ btn2 }) => ({
                btn2: (btn2 === true) ? false : true
            }))
        }
        if (btn === "btn3") {
            this.setState(({ btn3 }) => ({
                btn3: (btn3 === true) ? false : true
            }))
        }
        if (btn === "btn4") {
            this.setState(({ btn4 }) => ({
                btn4: (btn4 === true) ? false : true
            }))
        }
        if (btn === "btn5") {
            this.setState(({ btn5 }) => ({
                btn5: (btn5 === true) ? false : true
            }))
        }
    }

    // update SupportDB
    saveFeedback = (vote: string) => {
        const { stitchClient, item, autoTCData } = this.props;
        const { btn1, btn2, btn3, btn4, btn5 } = this.state;
        const voted = this.state.voted;

        let assignee = autoTCData?.autoTC.allAutoTCData[item.id] ? autoTCData?.autoTC.allAutoTCData[item.id] : '';
        let voter = autoTCData?.autoConfig.currentUserFI ? autoTCData?.autoConfig.currentUserFI : '';
        let result = vote === 'upvoted' ? 'positive' : 'negative';
        let caseAnalysisId = autoTCData?.autoTC.caseAnalysisId ? autoTCData?.autoTC.caseAnalysisId : '';

        // Get UTC Datetime format for SupportDB globally
        var date = new Date();
        var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        let feedbackTime = new Date(now_utc);
        let feedbackVal = [];

        let feedbackObj: feedbackObj = {
            caseNumber: item.id,
            assignee: assignee,
            voter: voter,
            reasons: [],
            feedback: result,
            caseAnalysisId: caseAnalysisId,
            updated: feedbackTime
        }

        if (vote === 'downvoted' && voted === false) {
            if (btn1) feedbackVal.push("improperSkills");
            if (btn2) feedbackVal.push("tooBusy");
            if (btn3) feedbackVal.push("higherPriorityWork");
            if (btn4) feedbackVal.push("notAvailable");
            if (btn5) feedbackVal.push("componentsMisclassified");
            feedbackObj.reasons = feedbackVal;
            this.setState({
                feedbackObj: feedbackObj
            })
        }


        // console.log('Sending feedback to Stitch');
        if ((vote === 'upvoted' && voted === false) || (vote === 'downvoted' && feedbackVal.length !== 0)) {
            stitchClient.callFunction("setAutoTC_Feedback", [feedbackObj]).then((response: any) => {
                console.log("Saved into feedback collection!");
                if (response.upsertedId) {
                    console.log(`_id: ${response.upsertedId.toString()}`);
                } else {
                    console.log(response);
                    console.log(`_id: ${response.insertedId.toString()}`);
                }
                this.setState({
                    voted: true,
                    votedResult: vote,
                    feedbackObj: feedbackObj
                });
                this.onFeedbackModalClose(true);
            }).catch((err: any) => {
                console.log(`result: ${err}`);
            })
        }
    }

    onFeedbackModalOpen = () => {
        const voted = this.state.voted;
        if (!voted) {
            this.setState({
                isFeedbackOpen: true
            });
        }
    }

    // Reset all state on modal close
    onFeedbackModalClose = (voted: boolean) => {
        if (voted) {
            this.setState({
                isFeedbackOpen: false
            })
        } else {
            this.setState({
                isFeedbackOpen: false,
                btn1: false,
                btn2: false,
                btn3: false,
                btn4: false,
                btn5: false
            });
        }
        // console.log('modal closed');
    }

    onClose = () => {
        this.onFeedbackModalClose(false);
        return this.setState({ isOpen: !this.state.isOpen })
    }

    render() {
        // console.log('In Feedback');
        const { item } = this.props;
        const { isOpen, isFeedbackOpen, btn1, btn2, btn3, btn4, btn5, votedResult } = this.state;

        let start = +new Date();
        let thumbsBtn;
        // console.log(item);

        if (votedResult === 'upvoted') {
            thumbsBtn = <span className="feedback-button voted">
                <span className="up-vote">
                    <FaRegThumbsUp size="20" />
                </span>
            </span>
        } else if (votedResult === 'downvoted') {
            thumbsBtn = <span className="feedback-button voted">
                <span className="down-vote">
                    <FaRegThumbsDown size="20" />
                </span>
            </span>
        } else if (item.feedback != null &&
            ((start - Date.parse(item.votedDatetime)) / 1000 < 28800)) {
            thumbsBtn = <span className="feedback-button voted">
                {item.feedback === 'positive' && <span className="up-vote">
                    <FaRegThumbsUp size="20" />
                </span>}
                {item.feedback !== 'positive' && <span className="down-vote">
                    <FaRegThumbsDown size="20" />
                </span>}
            </span>
        } else {
            thumbsBtn = <span className="feedback-button unvoted">
                <span className={"up-vote hvr-pulse-grow"} onClick={(ev: any) => { ev.stopPropagation(); this.saveFeedback('upvoted'); }}>
                    <FaRegThumbsUp size="20" />
                </span>
                <span className={"down-vote hvr-pulse-grow"} onClick={(ev: any) => { ev.stopPropagation(); this.onFeedbackModalOpen(); }}>
                    <FaRegThumbsDown size="20" />
                </span>
            </span>
        }

        return (
            <>
                {thumbsBtn}
                {isFeedbackOpen &&
                    <span onClick={(ev: any) => ev.stopPropagation()}><Modal
                        size="small"
                        contentClassName="feedback-modal"
                        open={isOpen}
                        setOpen={this.onFeedbackModalClose}>

                        <div className="feedbackModal-header">
                            <strong>Why wasn't this a good suggestion?</strong>
                        </div>
                        <div className="feedbackModal-body">
                            <Button className={"feedback-Btn " + (btn1 ? "selected" : "unselected")} onClick={() => this.updateFeedback("btn1")}>Skills mismatch</Button>
                            <Button className={"feedback-Btn " + (btn2 ? "selected" : "unselected")} onClick={() => this.updateFeedback("btn2")}>Too busy</Button>
                            <Button className={"feedback-Btn " + (btn3 ? "selected" : "unselected")} onClick={() => this.updateFeedback("btn3")}>Have higher priority work</Button>
                            <Button className={"feedback-Btn " + (btn4 ? "selected" : "unselected")} onClick={() => this.updateFeedback("btn4")}>Won't be available</Button>
                            <Button className={"feedback-Btn " + (btn5 ? "selected" : "unselected")} onClick={() => this.updateFeedback("btn5")}>Incorrect components</Button>
                        </div>
                        <div className="feedbackModal-footer">
                            <div className="feedbackModal-submit">
                                <Button
                                    variant='primary'
                                    className="feedbackModal-submitBtn btn btn-primary"
                                    onClick={(ev: any) => { ev.stopPropagation(); this.saveFeedback('downvoted'); }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Modal></span>
                }
            </>
        )

    }
}

export default Feedback;