/* eslint-disable */ // Ignores 'react-hooks/rules-of-hooks' warning caused by this file

import { useMediaQuery } from "@mui/material";
import { Breakpoint, Theme, useTheme } from "@mui/material/styles";

type BreakpointOrNull = Breakpoint | null;

// Migrating from material-ui's 'withWidth()' - https://mui.com/material-ui/react-use-media-query/
export function useWidth() {
    const theme: Theme = useTheme();
    const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse();

    return (
        keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
            const matches = useMediaQuery(theme.breakpoints.up(key));

            return !output && matches ? key : output;
        }, null) || "xs"
    );
}
