import React from "react";
import Icon from '@leafygreen-ui/icon';
import ToolTip from '@leafygreen-ui/tooltip';
import useDarkMode from "use-dark-mode";
import { getMostRecentPhn } from "..";

export function PhnIcon({ project }: any) {
  const darkMode = useDarkMode(false, {
    classNameDark: 'dark',
    classNameLight: 'light'
  });
  
  const phn = getMostRecentPhn(project);

  return phn ? <ToolTip
    darkMode={darkMode.value ? true : false}
    align="bottom"
    justify="end"
    trigger={
      <span><Icon glyph="Warning" fill="#FF0000" style={{ marginRight: "10px", flex: "0 0 auto" }} data-test-id={`${project.id}-phn-icon`} /></span>
    }
    triggerEvent="hover">
    <div className="tc-tooltip-popover">
      Project Handling Note(s) available.
    </div>
  </ToolTip> : null;

}