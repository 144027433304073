/* eslint-disable react-hooks/exhaustive-deps */

import React, { FunctionComponent, useEffect, useState } from "react";
import Modal from '@leafygreen-ui/modal';
import Badge from '@leafygreen-ui/badge';
import ReactMarkdown from 'react-markdown';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useWidth } from "../_hooks/useWidth";
import "./ActionModal.css";
import { renderComponents } from "../_Configs/AutodeskHubColumns";
import { PhnBanner } from '../Phn';

type Props = {
    callback: any,
    stitchClient: any,
    selectedItem: any
}

const TRUNCATE_LENGTH = 370;
const LINK_COLOR = { color: "#2C92BB", "marginLeft": "5px" };
const MODAL = {
    CASE: 'CASE',
    COMMENT: 'COMMENT',
    JIRA: 'JIRA'
};

const ActionModal: FunctionComponent<Props> = (props: Props) => {
    const [isOpen, setIsOpen] = useState(true);
    const [data, setData] = useState<any>({});
    const [params, setParams] = useState<any>({});

    const width = useWidth();

    useEffect(() => {
        getCaseDetailsModalData(props.stitchClient);
    }, []);

    const getCaseDetailsModalData = (client: any) => {
        const { selectedItem } = props;

        if (!selectedItem) return;

        // default
        let params = {
            id: selectedItem.sfdcCaseId,
            commentId: null,
            type: MODAL.CASE
        }

        if (selectedItem.sfdcCommentId != null) {
            params = {
                id: selectedItem.sfdcCaseId,
                commentId: selectedItem.sfdcCommentId,
                type: MODAL.COMMENT
            }
        } else if (selectedItem.jiraCaseId != null) {
            params = {
                id: selectedItem.jiraCaseId,
                commentId: null,
                type: MODAL.JIRA
            }
        }

        setParams(params);

        client && client.callFunction("getCaseDetailsModalData", [params]).then((response: any) => {
            if (response && response.status === 'success') {
                setData(response.data);
            } else {
                console.log('Error: No Data for ActionModal');
            }
        }).catch((err: any) => {
            console.error(err);
        })
    }

    const onClose = () => {
        props.callback && props.callback();

        setIsOpen(!isOpen);
    }

    const onSize = () => {
        let size: any = 'default';

        switch (width) {
            case 'xs':
            case 'sm':
                size = 'small';
                break;
            case 'md':
                size = 'default';
                break;
            case 'lg':
            case 'xl':
                size = 'large';
                break;
        }

        return size;
    }

    const truncateWithEllipses = (text: string, max: number) => {
        if (text)
            return <span>{text.substr(0, max - 1)}{text.length > max ? <span>&hellip;</span> : ''}</span>;
    }


    const renderTags = (item: any) => {
        if (item)
            if (params.type === MODAL.JIRA && item.components && item.components.length > 0) {
                return (
                    item.components.map((comp: any, idx: number) => (
                        <Badge className="case-component" variant="darkgray" key={idx}>{comp}</Badge>
                    ))
                );
            } else {
                return <span>
                    {renderComponents(item)}
                </span>
            }

        return ''
    }

    const renderBadge = () => {
        const { selectedItem } = props;

        let field = '';
        let value = null;

        if (params.type === MODAL.JIRA) {
            field = 'WAITING';
            value = selectedItem ? selectedItem.CALC_waitingTime : selectedItem;
        } else {
            field = 'SLA';
            value = selectedItem ? <Badge className={"sla-badge badge-" + selectedItem.CALC_slaColor} variant={selectedItem.CALC_slaColor}>
                {selectedItem.CALC_slaTimeStr}
            </Badge> : ''
        }

        return <div className="item">
            <dt className="summary-table-row-title">{field}</dt>
            <dd className="summary-table-row-value"> {value}</dd>
        </div>
    }

    const renderStickies = (item: any) => {
        if (item) {
            let stickiesList: Array<any> = [];
            if (item.stickies && Object.keys(item.stickies).length > 0) {
                for(let r in item.stickies) {
                    let region = item.stickies[r];
                    Array.prototype.push.apply(stickiesList,region);
                }
                return (
                    stickiesList  && stickiesList.map((sticky: any, idx: number) => (
                        <dd className="summary-table-row-value" key={'stickyline-' + idx}><Badge className={"sla-badge badge-" + stickiesBadgeColor(sticky.region)} variant={stickiesBadgeColor(sticky.region)} key={'sticky-' + idx}>{sticky.region}: {sticky.name}</Badge></dd>
                    ))
                );
            }
        }
        return ''
    }

    const stickiesBadgeColor = (region: string): any => {
        let REGION_state_color = 'lightgray';

        if(['NA','AMER'].indexOf(region) > -1) {
            REGION_state_color = "yellow";
        } else if(['EMEA'].indexOf(region) > -1) {
            REGION_state_color = "green";
        } else if(['APAC'].indexOf(region) > -1) {
            REGION_state_color = "blue";
        }

        return REGION_state_color;
    }

    const renderId = (item: any) => {
        let caption = '';
        let text = null;

        switch (params.type) {
            case MODAL.CASE:
            case MODAL.COMMENT:
                caption = 'CASE NUMBER';
                text = item.caseNumber ?? 'N/A';
                break;
            case MODAL.JIRA:
                caption = "JIRA Task";
                text = <a href={"https://jira.mongodb.org/browse/" + item.jiraCaseId} target="_blank" rel="noopener noreferrer">{item.jiraCaseId}</a>

                break;
        }

        return (
            <div className="item">
                <dt className="summary-table-row-title">{caption}</dt>
                <dd className="summary-table-row-value">{text ? text : 'N/A'}</dd>
            </div>
        )
    }

    const renderSeverity = (item: any) => {
        let field = '';
        let value = null;

        if (params.type === MODAL.JIRA) {
            field = 'PRIORITY';
            value = item.priority;
        } else {
            field = 'SEVERITY';
            value = item.severity;
        }

        return <div className="summary-table-row">
            <div className="item">
                <dt className="summary-table-row-title">{field}</dt>
                <dd className="summary-table-row-value">{value}</dd>
            </div>
        </div>

    }

    const renderDate = (item: any) => {
        let newDate = new Date(item);

        return <span>{newDate.toISOString()}</span>
    }

    const renderNextActionBadge = () => {
        const { selectedItem } = props;

        return selectedItem && selectedItem.ftsNextAction && selectedItem.ftsNextAction !== '' ? <Badge className={"sla-badge badge-" + selectedItem.CALC_nextActionColor} variant={'lightgray'}>
            {selectedItem.ftsNextAction}
        </Badge> : ''
    }

    const renderLastCommenter = (item: any) => {
        return (params.type === MODAL.JIRA)
            ?
            <div className="item">
                <dt className="summary-table-row-title">LAST COMMENTER</dt>
                <dd className="summary-table-row-value">{item.lastCommenter ? item.lastCommenter : 'N/A'}</dd>
            </div>
            :
            <>
                <div className="">
                    <dt className="summary-table-row-title">LAST COMMENTER</dt>
                    <dd className="summary-table-row-value">{item.lastCommenter ? item.lastCommenter.username : 'N/A'}</dd>
                </div>
                <div className="item">
                    <dt className="summary-table-row-title">COMMENTER TIMEZONE</dt>
                    <dd className="summary-table-row-value">{item.lastCommenter ? item.lastCommenter.timezone : 'N/A'}</dd>
                </div>
            </>
    }

    const renderSalesForceCaseUrl = () => {
        const { selectedItem } = props;

        let uri = `https://hub.corp.mongodb.com/case/${selectedItem.id}`;

        if (selectedItem.source && selectedItem.source.includes('mongodbgov'))
            uri = `https://${selectedItem.source}.my.salesforce.com/${selectedItem.sfdcCaseId}`; 

        return <div className="">
            <dt className="summary-table-row-title">SFSC</dt>
            <dd className="summary-table-row-value">
                <a href={uri} target="_blank" rel="noopener noreferrer" title="SFSC">
                    <FaExternalLinkAlt size="20px" color={LINK_COLOR.color} />
                </a>
            </dd>
        </div> 
    }

    const isGovCloud = () => {
        const { selectedItem } = props;

        return selectedItem.source && selectedItem.source.includes('mongodbgov');
    }

    return (
        <React.Fragment>
            <Modal
                size={onSize()}
                contentClassName="action-modal"
                open={isOpen}
                setOpen={onClose}
            >
                <div data-testid="desc-container" className='desc-container'>
                    <strong>{data.summary}</strong>
                </div>
                <PhnBanner project={props.selectedItem} />
                <div className='container'>
                    <div className="summary-table-1">
                        <div className="summary-table-row">
                            <div>
                                <dt className="summary-table-row-title">ASSIGNEE</dt>
                                <dd className="summary-table-row-value">{data.assignee ?? 'N/A'}</dd>
                            </div>
                            {renderLastCommenter(data)}
                        </div>
                        <div className="summary-table-row">
                            <div>
                                <dt className="summary-table-row-title">COMPANY - PROJECT</dt>
                                <dd className="summary-table-row-value">
                                    {data.company ?? 'N/A'}
                                </dd>
                            </div>
                            {renderId(data)}
                        </div>
                        <div className="summary-table-row-bottom">
                            <div>
                                <dd className="summary-table-row-value">
                                    {truncateWithEllipses(data.description, TRUNCATE_LENGTH)}
                                </dd>
                            </div>
                        </div>
                    </div>
                    <div className="summary-table-2">
                        {renderSeverity(data)}
                        <div className="summary-table-row">
                            {renderBadge()}
                        </div>
                        <div className="summary-table-row-bottom">
                            <div className="">
                                <dt className="summary-table-row-title">TAGS</dt>
                                <dd className="summary-table-row-value">
                                    {renderTags(data)}
                                </dd>
                            </div>
                        </div>
                        <div className="summary-table-row-bottom">
                            <div className="">
                                <dt className="summary-table-row-title">STICKIES</dt>
                                {renderStickies(data)}
                            </div>
                        </div>
                    </div>
                </div>
                {data.isFTS &&
                    <div>
                        <br/>
                        <div className='desc-container'>
                            <strong>Latest FTS Comment</strong>
                        </div>
                        <div className='container'>
                            <div className="summary-table">
                                <div className="summary-table-row">
                                    <div>
                                        <dt className="summary-table-row-title">CREATED BY</dt>
                                        <dd className="summary-table-row-value">
                                            {data.ftsCommentLastModifiedByName}
                                        </dd>
                                    </div>
                                    <div>
                                        <dt className="summary-table-row-title">LAST MODIFIED</dt>
                                        <dd className="summary-table-row-value">
                                            {renderDate(data.ftsCommentLastModifiedDate)}
                                        </dd>
                                    </div>
                                    <div>
                                        <dt className="summary-table-row-title">NEXT ACTION</dt>
                                        <dd className="summary-table-row-value">
                                            {renderNextActionBadge()}
                                        </dd>
                                    </div>
                                </div>
                                <div className="summary-table-row-bottom">
                                    <div>
                                        <dd className="summary-table-row-value fts-comment">
                                            <ReactMarkdown children={data.ftsComment}/>
                                        </dd>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="summary-table-row-single">
                    {data.caseNumber && renderSalesForceCaseUrl()
                        // <div className="">
                        //     <dt className="summary-table-row-title">SFSC</dt>
                        //     <dd className="summary-table-row-value"> 
                        //         <a href={"https://hub.corp.mongodb.com/case/" + data.caseNumber} target="_blank" rel="noopener noreferrer" title="SFSC">
                        //             <FaExternalLinkAlt size="20px" color={LINK_COLOR.color} />
                        //         </a>
                        //     </dd>
                        // </div>
                    }
                    &nbsp;&nbsp;&nbsp;
                    {(data.cloudProjectId && !isGovCloud()) &&
                        <div className="">
                            <dt className="summary-table-row-title">ATLAS UI</dt>
                            <dd className="summary-table-row-value">
                                <a href={`https://mongodb.lightning.force.com/lightning/r/Cloud_Project__c/${data.cloudProjectId}/view`} target="_blank" rel="noopener noreferrer" title="ATLAS UI">
                                    <FaExternalLinkAlt size="20px" color={LINK_COLOR.color} />
                                </a>
                            </dd>
                        </div>
                    }
                    &nbsp;&nbsp;&nbsp;
                    {(data.caseNumber && !isGovCloud()) &&
                        <div className="">
                            <dt className="summary-table-row-title">ADP</dt>
                            <dd className="summary-table-row-value">
                                <a href={"https://proactive.corp.mongodb.com/diagnostics/adp/" + data.caseNumber} target="_blank" rel="noopener noreferrer" title="ADP">
                                    <FaExternalLinkAlt size="20px" color={LINK_COLOR.color} />
                                </a>
                            </dd>
                        </div>
                    }
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default ActionModal;
