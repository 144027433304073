import React from 'react';
import { flushSync } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { Header, NavItem } from '../Header/Header';
import { Stitch, StitchUser, StitchAppClient } from 'mongodb-stitch-browser-sdk';
import { GoogleRedirectCredential, UserApiKeyCredential } from 'mongodb-stitch-browser-sdk';
import InitialTableConfig, { HubDataType } from '../_Configs/InitialTableConfig';
import HubDataExtension from '../_Extensions/HubDataExtension';
import AppLoader from '../AppLoader/AppLoader';
import { CSSTransition } from 'react-transition-group';
import TCHelper from '../TCHelper/TCHelper';
//import { HubTableWrapper } from '../HubTableWrapper/HubTableWrapper';
import { AutodeskTableWrapper } from '../AutodeskTableWrapper/AutodeskTableWrapper';
import DisconnectModal from '../DisconnectModal/DisconnectModal';
import ActivityMonitor from '../ActivityMonitor/ActivityMonitor';
import { HubDataAddAutoTCExtension, GetHubConfigData } from '../_Extensions/HubDataAddAutoTCExtension';
import GlobalStorage from '../_Generics/GlobalStorage';
import Watcher from '../../Watcher'
import Toggle from '@leafygreen-ui/toggle'
import { debounce } from "throttle-debounce";
import { ActorDetails } from '@ts-tools/tools-nav/dist/esm/TopBar/TopBar';

// Stage and Prod
declare var APP_CONFIG: any;

function optionalRequire() {
    try { return require('../../config.json'); } catch (e) { console.log(e); return false; }
}
const LOCAL_APP_CONFIG = optionalRequire();

type Props = {}

type State = {
    currentUser: StitchUser | undefined,
    client: StitchAppClient | undefined,
    isLoading: boolean,
    navData: NavItem[],

    currentHubAbbr: string,
    currentNavType: string,
    hubConfigs: HubDataType[],
    data?: any,
    autoConfig: any,
    isDisconnected: boolean,
    showActivityMonitor: boolean,
    activityMonitorRecording: boolean,
    rollingEvents: boolean,
    changeEvents: any,
    autoTC: any,
    isTC: boolean,
    TC: string,
    
    displayRecommendation: boolean,
    displayAlert: boolean,
    supportAlerts: string[],
    counter: number;
    watcher: any;

    last_sync_jira_dt: number;
    last_sync_sfdc_dt: number;
    current_eztc_status: boolean;
    eztc_inspector_url: string;
    env_name: string;

    actorDetails?: ActorDetails[];
}

const staticNavItems: NavItem[] = [{
    type: "static",
    title: "TC Helper",
    value: "TCHELPER"
}]

//const MINUTE = 60 * 1000;

const dataWorker: any = () => {
    setInterval(() => {
        postMessage({ foo: 'bar' });
    }, 60 * 1000);
}

let changeEventTimeStamp: any = new Date();

const greaterThanOneMinuteAgo = (date: any) => {
    var aMinuteAgo = (new Date().getTime() - date.getTime() < (60 * 1000)) ? false : true;
    //console.log(date);
    console.log(new Date());
    console.log('over a minute: ', aMinuteAgo)
    return aMinuteAgo;
}

class App extends React.Component<Props,
    State> {
    constructor(props: Props) {
        super(props);
        const initialDataForm = InitialTableConfig();

        const urlPath = window.location.pathname.split('/')[1];
        const allNavItems = this.mapConfigAndCountToNavItems(initialDataForm, {});
        const viewToShow = (urlPath && allNavItems.filter((i) => i.value === urlPath.toUpperCase()).length > 0 ? urlPath.toUpperCase() : null);
        const navType = (urlPath === "tchelper" ? "static" : "hub");
        this.state = {
            currentUser: undefined,
            client: undefined,

            // We map the config to an array of object {title, count}
            navData: allNavItems,
            currentNavType: navType,
            currentHubAbbr: (viewToShow ? viewToShow : initialDataForm[0].hubAbbr), // TODO from localstorage
            hubConfigs: initialDataForm,
            autoConfig: null,
            isTC: false,
            autoTC: null,
            TC: '',
            isDisconnected: false,
            showActivityMonitor: false,
            activityMonitorRecording: false,
            rollingEvents: true,
            changeEvents: [],
            isLoading: true,
            displayRecommendation: false,
            displayAlert: false,
            supportAlerts: [],
            counter: 0,
            watcher: null,
            last_sync_jira_dt: 0,
            last_sync_sfdc_dt: 0,
            current_eztc_status: false,
            eztc_inspector_url: '',
            env_name: '',
        }
    }
  
    onFocusDebounced = debounce(250, () => {
        console.log('onfocus');
      
        const {isDisconnected } = this.state;

        if(!isDisconnected)
            this.loadData(); 
    });
 
    componentWilUnmount() {
        window.removeEventListener("focus", this.onFocusDebounced)
    }

    /**
    * When component mounts, ensure stitch client is loaded
    */
    componentDidMount() {

        window.addEventListener("focus", this.onFocusDebounced)

        // Hijack login for local testing This should be removed in prod.
        if (typeof LOCAL_APP_CONFIG !== 'undefined') {
            this._loadStitchClient(LOCAL_APP_CONFIG);
        } else {
            this._loadStitchClient(APP_CONFIG);
        }
    }

    // componentWillUnmount() {
    //     let {watcher} = this.state;
    //     watcher.close(); 
    //     console.log('Closing watcher');
    // }

    /**
   * Load the stitch client.
   * @param config
   */
    async _loadStitchClient(config: any) {
        // Get a client for your Stitch app, or instantiate a new one
        const client = Stitch.hasAppClient(config.appId)
            ? Stitch.getAppClient(config.appId)
            : Stitch.initializeAppClient(config.appId);

        // Manage user authentication state Check if already authenticated then process
        // the redircet to finish login
        if (client.auth.hasRedirectResult()) {
            await client
                .auth
                .handleRedirectResult()
                .catch((err) => {
                    console.log(err);
                });
            console.log("Processed redirect result");
        }

        if (client.auth.isLoggedIn) {
            // The user is logged in
            flushSync(() => {
                this.setState({ currentUser: client.auth.user, client: client });
            })
            console.log("User Logged into Stitch", client.auth.user);
            if (client) {
                client.callFunction('getContextValue', ['eztc_inspector'])
                    .then((value: any) => {
                        flushSync(() => {
                            this.setState({ eztc_inspector_url: value.url });
                        })
                    });
                client.callFunction('getContextValue', ['env_name'])
                    .then((value: any) => {
                        flushSync(() => {
                            this.setState({ env_name: value.name });
                        })
                    });

                client.callFunction('getActorDetails', [])
                .then((value: any) => {
                    flushSync(() => {
                        this.setState({ actorDetails: value });
                    })
                })
                .catch ((err: any) => {
                    console.error(`Failed to get actor details ${err}`)
                });
            }
            this.getAllData('TC', "hub", client).then(() => {
                let { data } = this.state;
                flushSync(() => {
                    this.setState({ watcher: new Watcher(client, data) });
                });
                this.setupWatchers('NONE',/*client*/'TC');
            }); // TODO: LOAD FROM LOCAL STORAGE

            if (this.state && this.state.currentHubAbbr !== 'TC') {
                this.getAllData(this.state.currentHubAbbr, this.state.currentNavType, client);
            }
            //setInterval(this.getAllData, 30 * 1000); 
            //setInterval(this.loadData, 60 * 1000);

            let code: any = dataWorker.toString();
            code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

            let blob = new Blob([code], { type: "application/javascript" });
            let worker = new Worker(URL.createObjectURL(blob));

            worker.onmessage = (event) => {
                console.log('onmessage');
                console.log(changeEventTimeStamp);
                this.loadData();
            }

        } else {
            // The user has not yet authenticated. Begin the Google login flow. (Only either
            // one below)
            console.log('The user has not yet authenticated. Begin the Google login flow.');

            if (process.env.REACT_APP_USER_KEY) {
                client.auth.loginWithCredential(new UserApiKeyCredential(process.env.REACT_APP_USER_KEY));
            } else {
                // 1. Authenticate with Google OAuth (Production / Staging)
                const credential = new GoogleRedirectCredential(config.googleRedirectURL);
                client.auth.loginWithRedirect(credential);
            }
        }
    }
 
    loadData = () => {

        let { counter, client, currentHubAbbr } = this.state;

        flushSync(() => { 
            counter !== 5 ? this.setState({ counter: ++counter }) : this.setState({ counter: 0 })
        })

        // console.log(this.state.counter);
        console.log("LOADDATA");
        console.log(currentHubAbbr);
        // this.getAllData(currentHubAbbr, "hub", client, this.state.counter === 4); 
        this.getAllData(currentHubAbbr, "hub", client, greaterThanOneMinuteAgo(changeEventTimeStamp));
    }

    updateView = (newNav: NavItem) => { // newNav.value = hubAbbr
        // Note that state is NOT 'set' when it calls the next function. setState is
        // lazy console.log(newNav);
        //const { currentNavType } = this.state;
        console.log("UPDATE VIEW");
        console.log(newNav.value);
        if (newNav.type === 'hub') {
            //TODO: check if data exists already
            let { currentHubAbbr } = this.state;

            flushSync(() => {
                this.setState({ currentNavType: newNav.type, currentHubAbbr: newNav.value, isLoading: true });
            });

            flushSync(() => { 
                this.setState({ currentHubAbbr: newNav.value, isLoading: true });
            })

            this.getAllData(newNav.value, newNav.type).then(() => {
                //let {client} = this.state;
                this.setupWatchers(currentHubAbbr, newNav.value);
            });
        } else {
            this.setState({ currentNavType: newNav.type, currentHubAbbr: newNav.value });

        }
    }

    mapConfigAndCountToNavItems(hubConfigs: HubDataType[], hubCount: any): NavItem[] {
        let configs: NavItem[] = hubConfigs.map((item) => {
            return {
                type: "hub",
                title: item.title,
                value: item.hubAbbr,
                count: item.hubCountProp(hubCount)
            }
        });
        //add static items
        staticNavItems.forEach((i: NavItem) => {
            configs.push(i);
        })
        return configs;
    }

    getAllData = (newView: string, newNavType: string, passedClient?: StitchAppClient, includeHubData: boolean = true) => {

        return new Promise(async (resolve: any, reject: any) => {

            let { client, currentHubAbbr, currentNavType } = this.state;
            newView = newView
                ? newView
                : currentHubAbbr;
            client = client
                ? client
                : passedClient;

            currentNavType = newNavType
                ? newNavType
                : currentNavType;

            if (currentNavType === "static") {
                this.setState({ isLoading: false });
                return;
            }

            if (client) {
                client.callFunction("getSupportHub", [newView])
                    .then((results: any) => {
                        console.log(results);
                        const hubData = HubDataExtension(results.hubData.data)
                        const hubCount = results.hubCount.data;
                        const hubAlert = results.hubAlert.data[0];
                        const eztc_status = results.Eztc;
                        let tcList : any = [];

                        let TC = 'NO SET';
                        if(results.TC.length > 0 && results.TC[0].real_name) {
                            TC = '';
                            results.TC.forEach((tc:any) => {
                                TC += tc.real_name + ' ';
                                tcList.push(tc.emailforlookup);
                            })                            
                        }

                        console.log("EZ/TC Status: " + eztc_status + ' Actual Tc: '+ TC);
                        let getHubConfigData = GetHubConfigData(results.autoTCData);
                        
                        flushSync(() => {
                            this.setState({autoTC : getHubConfigData})
                        });
                        
                        console.log(this.state.autoTC);     //const

                        let last_sync_jira_dt = results.hubData.last_sync_jira_dt;
                        let last_sync_sfdc_dt = results.hubData.last_sync_sfdc_dt;

                        // Promise.all([

                        //     client.callFunction("getHubData", ["data", newView]),
                        //     client.callFunction("getHubData", ["count", ""]),
                        //     client.callFunction("getAutoTCData", [])
                        // ]).then((results: any[]) => {
                        //console.log(results);
                        //const hubData = HubDataExtension(results[0].data)
                        //const hubCount = results[1].data;
                        //console.log(results[2]);

                        //const autoTC = GetHubConfigData(results[2])
                        // This is hacky to pass to the Feedback control
                        GlobalStorage.autoTCData = this.state.autoTC;

                        const hubTCMergedData = HubDataAddAutoTCExtension(hubData, this.state.autoTC);

                        const { hubConfigs, currentHubAbbr, data } = this.state;

                        if (includeHubData) {

                            if (data && currentHubAbbr === 'TC') {
                                this.debuggerListDifferences(data, hubData);
                            }

                            let slowcopy: any;
                            if (data) {
                                slowcopy = data;
                                for (let prop in hubTCMergedData) {
                                    slowcopy[prop] = hubTCMergedData[prop];
                                }
                            } else {
                                slowcopy = hubTCMergedData;
                            }

                            // need timestamp for Countdown and followup ElapsedTime components 
                            // if(currentHubAbbr === 'TC') {
                            //     slowcopy.SLA = slowcopy.SLA.map((obj:any) => ({ ...obj, timestamp: results.hubData.timestamp }))
                            //     slowcopy.FSA = slowcopy.FSA.map((obj:any) => ({ ...obj, timestamp: results.hubData.timestamp }))
                            //     slowcopy.UNA = slowcopy.UNA.map((obj:any) => ({ ...obj, timestamp: results.hubData.timestamp }))
                            //     slowcopy.REVACT = slowcopy.REVACT.map((obj:any) => ({ ...obj, timestamp: results.hubData.timestamp }))
                            // }


                            // HACK, data wasn't really updating values
                            // this.setState({
                            //     data: {}
                            // })
                            console.log('+++++++');
                            console.log(this.state.autoTC.autoConfig);

                            flushSync(() => { 
                                this.setState({
                                    displayRecommendation: this.state.autoTC.autoConfig.displayRecommendation,
                                    displayAlert: this.state.autoTC.autoConfig.isTC || this.state.autoTC.autoConfig.isToolsTeam,
                                    supportAlerts: hubAlert,
                                    data: slowcopy,
                                    navData: this.mapConfigAndCountToNavItems(hubConfigs, hubCount),
                                    isTC: this.state.autoTC.autoConfig.isTC,
                                    TC: TC,
                                    isLoading: false,
                                    isDisconnected: false,
                                    current_eztc_status: eztc_status,
                                    last_sync_jira_dt: last_sync_jira_dt,
                                    last_sync_sfdc_dt: last_sync_sfdc_dt,
    
                                });
                            })


                        } else {
                            flushSync(() => { 
                                this.setState({
                                    displayRecommendation: this.state.autoTC.autoConfig.displayRecommendation,
                                    displayAlert: this.state.autoTC.autoConfig.isTC || this.state.autoTC.autoConfig.isToolsTeam,
                                    supportAlerts: hubAlert,
                                    navData: this.mapConfigAndCountToNavItems(hubConfigs, hubCount),
                                    isTC: this.state.autoTC.autoConfig.isTC,
                                    TC:TC,
                                    isLoading: false,
                                    isDisconnected: false,
                                    current_eztc_status: eztc_status,
                                    last_sync_jira_dt: last_sync_jira_dt,
                                    last_sync_sfdc_dt: last_sync_sfdc_dt,
                                });
                            })
                        }

                        resolve({
                            'error': null,
                            'success': true
                        });

                    }).catch((err: any) => {
                        console.error(err);
                        this.setState({ isDisconnected: true });
                        reject({
                            'error': err,
                            'success': false
                        });
                    })
            }
        });
    }

    fieldSorter = (fields: any) => (a: any, b: any) => fields.map((o: string) => {
        let dir = 1;
        if (o[0] === '-') { dir = -1; o = o.substring(1); }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
    }).reduce((p: any, n: any) => p ? p : n, 0);


    setupWatchers = (oldview: string, view: string) => {

        if (oldview === view) return; // same tab selected

        let { data, changeEvents, navData, watcher, currentUser } = this.state;

        watcher.close();

        Watcher.view = view;

        if (view === 'TC') {

            watcher.watchTrafficCop((result: any) => {

                let { activityMonitorRecording, rollingEvents } = this.state;

                const { type, operation, row, changeEvent } = result;

                if (activityMonitorRecording && operation === 'log') {
                    changeEvents.push(changeEvent);
                    // changeEvents = changeEvents.concat(changeEvent);

                    if (rollingEvents && changeEvents.length > 500) {
                        changeEvents.shift();
                    }

                    return;
                }

                if (operation === 'log') {
                    return;
                }

                console.log({ type: type, operation: operation, row: row /* , changeEvent : changeEvent */ });

                changeEventTimeStamp = new Date(); //row.timestamp;

                let ndx = data[type].map(function (e: any) { return e.id; }).indexOf(row.id);
                let expand: any = null;
                let format: any = {};

                switch (operation) {
                    case 'update':

                        format = {
                            [type]: [row]
                        }

                        expand = HubDataExtension(format);
                        data[type][ndx] = JSON.parse(JSON.stringify(expand[type][0]));

                        // sort
                        if (type === "SLA") {
                            data[type].sort(this.fieldSorter(['sla']));
                        } else if (type === "UNA") {
                            data[type].sort(this.fieldSorter(['follow_up']));
                        } else if (type === "REVACT") {
                            data[type].sort(this.fieldSorter(['sev', '-waiting']));
                        } else if (type === "FSA") {
                            data[type].sort(this.fieldSorter(['fts_next_action']));
                        }

                        break;
                    case 'insert':

                        format = {
                            [type]: [row]
                        }
                        expand = HubDataExtension(format);
                        if (ndx === -1) {
                            data[type] = data[type].concat(expand[type][0]);

                            // duplicated!!!
                            // sort
                            if (type === "SLA") {
                                data[type].sort(this.fieldSorter(['sla']));
                            } else if (type === "UNA") {
                                data[type].sort(this.fieldSorter(['follow_up']));
                            } else if (type === "REVACT") {
                                data[type].sort(this.fieldSorter(['sev', '-waiting']));
                            } else if (type === "FSA") {
                                data[type].sort(this.fieldSorter(['fts_next_action']));
                            }

                        } else {
                            data[type][ndx] = JSON.parse(JSON.stringify(expand[type][0]));
                        }
                        break;
                    case 'delete':

                        if (ndx !== -1) {
                            data[type].splice(ndx, 1);
                            let result = [...data[type]]
                            data[type] = result;
                        }
                        break;
                }
                if ((operation === 'insert' || operation === 'delete')) {

                    let navCopy = JSON.parse(JSON.stringify(navData));
                    if (type === 'FSA') {
                        let index = navCopy.map((item: any) => { return item.value; }).indexOf('FTS');
                        let splt = navCopy[index].value.split('/');
                        let fsaCnt = parseInt(splt[0]);
                        let fswCnt = parseInt(splt[1]);
                        //navData[index].count = `${fsaCnt}/${fsaCnt + fswCnt}`;  
                        navCopy[index] = {
                            title: 'FTS',
                            value: 'FTS',
                            count: `${fsaCnt}/${fsaCnt + fswCnt}`
                        }
                    } else if (type === 'SLA') {
                        let index = navCopy.map((item: any) => { return item.value; }).indexOf('TC');
                        //navData[index].count = data[type].length; 
                        navCopy[index] = {
                            title: 'Traffic Cop',
                            value: 'TC',
                            count: data[type].length
                        };
                    } else if (type === 'UNA') {
                        let index = navCopy.map((item: any) => { return item.value; }).indexOf('UNAS');
                        //navData[index].count = data[type].length; 
                        navCopy[index] = {
                            title: 'Unassigned',
                            value: 'UNAS',
                            count: data[type].length
                        }

                    } else if (type === 'REVACT') {
                        let index = navCopy.map((item: any) => { return item.value; }).indexOf('REVS');
                        //navData[index].count = data[type].length; 
                        navCopy[index] = {
                            title: 'Reviews',
                            value: 'REVS',
                            count: data[type].length
                        }
                    }

                    flushSync(() => { 
                        this.setState({
                            data: data,
                            navData: navCopy
                        });
                    })
                } else {
                    this.setState({ data: data }, () => {
                        // console.log(this.state);  
                    });
                }
            });
        }

        if (view === 'USER') {

            let email: any = currentUser?.profile?.email;

            if (email) {

                let user = email.match(/^([^@]*)@/)[1]

                // TODO: replace with hard-coded self for local testing
                watcher.watchMyCases(user/* 'samuel.barone' */, (result: any) => {

                    const { actions } = result;
                    let updateNav: boolean = false;
                    let navIndex = navData.map((item: any) => { return item.value; }).indexOf(view);

                    actions.forEach((action: any) => {

                        let ndx = data[action.type].map((e: any) => { return e.id; }).indexOf(action.row.id);
                        let format: any = {};
                        let expand: any = null;

                        switch (action.operation) {
                            case 'update':
                                format = {
                                    [action.type]: [action.row]
                                }
                                expand = HubDataExtension(format);
                                data[action.type][ndx] = JSON.parse(JSON.stringify(expand[action.type][0]));
                                break;
                            case 'insert':
                                format = {
                                    [action.type]: [action.row]
                                }
                                expand = HubDataExtension(format);
                                if (ndx === -1) {
                                    data[ndx] = data[action.type].concat(expand[action.type][0]);
                                    updateNav = true;
                                    navData[navIndex].count = (Number(navData[navIndex].count ?? 0) + 1).toString()
                                } else {
                                    data[action.type][ndx] = JSON.parse(JSON.stringify(expand[action.type][0]));
                                }
                                break;
                            case 'delete':
                                if (ndx !== -1) {
                                    data[action.type].splice(ndx, 1);
                                    let result = [...data[action.type]]
                                    data[action.type] = result;
                                    updateNav = true;
                                    navData[navIndex].count = (Number(navData[navIndex].count ?? 0) - 1).toString()
                                }
                                break;
                        }
                    });

                    if (updateNav) {
                        this.setState({
                            data: data,
                            navData: navData
                        });
                    } else {
                        this.setState({ data: data });
                    }
                });

            } else {
                console.error("Unable to Change Stream [My Cases] User Undefined");
            }

        }
    }

    public debuggerListDifferences(local: any, remote: any) {
        this.debuggerCompare(local.SLA, 'SLA', remote.SLA);
        this.debuggerCompare(local.FSA, 'FSA', remote.FSA);
        this.debuggerCompare(local.UNA, 'UNA', remote.UNA);
    }

    private debuggerCompare(local: any, type: string, remote: any) {

        // add properties that you want to see
        let props = ['CaseNumber'];

        var missing = remote.filter((o1: any) => {
            return !local.some((o2: any) => {
                return o1.CaseNumber === o2.CaseNumber;
            });
        }).map((o: any) => {
            return props.reduce((newo: any, name: string) => {
                newo[name] = o[name];
                return newo;
            }, {});
        });

        var extra = local.filter((o1: any) => {
            return !remote.some((o2: any) => {
                return o1.CaseNumber === o2.CaseNumber;
            });
        }).map((o: any) => {
            return props.reduce((newo: any, name: string) => {
                newo[name] = o[name];
                return newo;
            }, {});
        });

        console.log('');
        console.log('Type: ' + type + ' missing:');
        console.log(JSON.stringify(missing));
        console.log('');
        console.log('Type: ' + type + ' does not belong:');
        console.log('------------------------------');
        console.log(JSON.stringify(extra));
        console.log('');
    }


    onClose = () => {
        this.setState({ isDisconnected: false })
        this.loadData(); 
    }

    onToggleActivityMonitor = () => {
        let { showActivityMonitor } = this.state;
        this.setState({ showActivityMonitor: !showActivityMonitor })


    }

    onRollingEvents = () => {
        let { rollingEvents } = this.state;
        let r = !rollingEvents;
        this.setState({ rollingEvents: r })
        return r;
    }

    render() {
        const {
            isLoading,
            hubConfigs,
            currentUser,
            client,
            currentHubAbbr,
            currentNavType,
            data,
            navData,
            isDisconnected,
            showActivityMonitor,
            activityMonitorRecording,
            changeEvents,
            autoTC,
            isTC,
            TC,
            last_sync_jira_dt,
            last_sync_sfdc_dt,
            actorDetails,
            displayRecommendation
        } = this.state;
        console.log(currentHubAbbr);
        const currentColumns = (currentNavType === "hub") ? hubConfigs.filter((i) => i.hubAbbr === currentHubAbbr)[0].views : [];
        console.log(currentColumns);
        console.log(data);
        console.log(currentNavType);
        console.log("APP RENDER");
        
        return (
            <BrowserRouter>
                <div className="App">
                    {currentUser && hubConfigs && <React.Fragment> 
                        <Header
                            currentUser={currentUser}
                            currentHubAbbr={currentHubAbbr}
                            navData={navData}
                            updateView={this.updateView}
                            actorDetails={actorDetails}
                            currentSyncJira={last_sync_jira_dt}
                            currentSyncSfdc={last_sync_sfdc_dt}
                        />

                        <TCHelper
                            stitchClient={client} isTC={isTC}
                            navType={currentNavType} hub={currentHubAbbr} eztc_inspector_url={this.state.eztc_inspector_url}
                        /> {/* <Hub stitchClient={client} data={data}/> */}
                        {/* {data && currentColumns.map((col, idx) => {
                          return data[col] && <HubTableWrapper key={"hub-wrapper-" + idx} view={col} data={data}></HubTableWrapper>
                        })} */}
                        {
                            (autoTC) ?
                                <div style={{ display: (currentNavType === "hub") ? "block" : "none" }}>
                                    <AutodeskTableWrapper 
                                        hub={currentHubAbbr}
                                        views={currentColumns}
                                        data={data}
                                        client={client}
                                        TC={TC}
                                        currentUser={autoTC.autoConfig}
                                        displayRecommendation={displayRecommendation}
                                    />
                                </div> : <></>
                        }
                        <CSSTransition in={isLoading} timeout={500} classNames="fade" unmountOnExit>
                            <AppLoader />
                        </CSSTransition>
                    </React.Fragment>}

                    {isDisconnected && <DisconnectModal onClose={this.onClose} />}
                    {showActivityMonitor && changeEvents && <ActivityMonitor events={changeEvents} onClose={this.onToggleActivityMonitor} onRollingEvents={this.onRollingEvents} />}

                    <div className='box'>
                        {activityMonitorRecording && <button className='activityButton' onClick={this.onToggleActivityMonitor}>Activity Monitor ({changeEvents.length})</button>}
                      &nbsp;
                       <Toggle
                            className='my-toggle'
                            size='xsmall'
                            onChange={(event) => { this.setState({ activityMonitorRecording: !activityMonitorRecording }) }}
                            checked={activityMonitorRecording}
                        />
                    </div>
                </div>
            </BrowserRouter>
        )
    }
}

export default App;