
import * as React from "react";
import Modal from "@leafygreen-ui/modal";
import { MdAccessAlarm } from "react-icons/md";
import Button from "@leafygreen-ui/button";
import "./DisconnectModal.css";
import {css} from "@ts-tools/emotion";

type Props = {
    onClose: Function
}

const refreshContainerStyle = css`
    display: flex;
    justify-content: center;
`;


const DisconnectModal: React.FunctionComponent<Props> = (props) => {
    const _onClose = (open: boolean) => {
        if (props.onClose) props.onClose();
    }
    const _onRefresh = () => {
        window.location.reload();
    }

    return <Modal
        size="small" 
        contentClassName="disconnect-modal"
        open={true}
        setOpen={_onClose}>
        <div className="warning-icon"><MdAccessAlarm /></div>
        <p>Your connection may be unstable, would you like to reconnect?</p>
        <div className={refreshContainerStyle}>
            <Button variant="primary" onClick={_onRefresh}>Refresh</Button>
        </div>
    </Modal>
}

export default DisconnectModal;